import React, { useState } from "react";
import { useNavigate } from "react-router";
import { DateRangePicker } from "react-date-range";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import styles from "./selectDate.module.css";
import { format } from "date-fns";
import { allParty } from "../../api/party";
import { useSelector, useDispatch } from "react-redux";
import AsyncSelect from "react-select/async";
import { creteOrderAction } from "../../Reduxstore/createOrder";
import moment from "moment-timezone"; // This can still be used if needed

function SelectDate() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showDate, setShowDate] = useState(false);
  const [date, setDate] = useState({
    startDate: new Date(), 
    endDate: new Date(), 
    key: "selection",
  });
  const [selectedOption, setSelectedOption] = useState(null);

  const token = useSelector((state) => state.login.token);

  // Fetch options from API for AsyncSelect
  const loadOptions = async () => {
    try {
      const response = await allParty(token);
      const formattedOptions = response.data.partiesList.map((item) => ({
        label: item.name,
        value: item.userId,
      }));
      return formattedOptions;
    } catch (error) {
      console.error("Error fetching options:", error);
      return [];
    }
  };

  const start = new Date(date.startDate);
  const end = new Date(date.endDate);

  const differenceInTime = end.getTime() - start.getTime();

  const differenceInDays = differenceInTime / (1000 * 3600 * 24);
console.log(differenceInDays)
  const handleAddItem = () => {
    if (!selectedOption || !date.startDate || !date.endDate || differenceInDays === 0) {
      toast.error(
        "Please fill in Party Name and select valid dates before proceeding!"
      );
      return;
    }

    // Format the dates before dispatching to Redux
    const formattedStartDate = format(date.startDate, "MMM dd, yyyy");
    const formattedEndDate = format(date.endDate, "MMM dd, yyyy");

    // Dispatch the selected values
    dispatch(creteOrderAction.partyName(selectedOption.label));
    dispatch(creteOrderAction.partyId(selectedOption.value));

    dispatch(creteOrderAction.startDate(formattedStartDate));
    dispatch(creteOrderAction.endDate(formattedEndDate));

    navigate("/order/addItem");
  };

  const handleShow = () => {
    setShowDate(!showDate);
  };

  const handleChange = (ranges) => {
    setDate(ranges.selection);
  };

  const handleChangeParty = (option) => {
    setSelectedOption(option);
    console.log(option)
  };

  return (
    <div className={styles.container}>
      <ToastContainer />
      <div className={`${styles.cardContainer}`}>
        <div className={styles.head}>
          <p>Create Order</p>
        </div>

        <div className={styles.party}>
          <label htmlFor="async-select" >
            Party Name
          </label>
          <AsyncSelect
            cacheOptions
            defaultOptions
            loadOptions={loadOptions}
            onChange={handleChangeParty}
            value={selectedOption}
            placeholder="Select an option"
            id="async-select"
          />
        </div>

        <div className={styles.dateBox}>
          <div className={styles.date}>
            <button className={styles.startDate} onClick={handleShow}>
              <p>Start Date</p>
              {`${moment(date.startDate).format("DD MMM YY")}`}
            </button>
            <button className={styles.endDate} onClick={handleShow}>
              <p>End Date</p>
              {`${moment(date.endDate).format("DD MMM YY")}`}
            </button>
          </div>

          {showDate && (
            <div className={`${styles.range} ${showDate ? styles.show : ''}`}>
              <DateRangePicker
                ranges={[date]}
                onChange={handleChange}
                minDate={new Date()}
                showDateDisplay={false}
                staticRanges={[]}
                inputRanges={[]}
                className={styles.DateRangePicker}
              />
            </div>
          )}
        </div>

        <div className={styles.additem} >
          <button onClick={handleAddItem}>Add Item</button>
        </div>
      </div>
    </div>
  );
}

export default SelectDate;
