import React, { useState } from "react";
import styles from "./orderStatus.module.css";
import { addExtraCharge } from "../../api/order";
import { useSelector } from "react-redux";

function ExtraCharge({ isOpen, onClose ,orderId ,updateData}) {

    const token = useSelector((state) => state.login.token);
    const [data, setData] = useState({})
    
    const saveExtraCharges = async() => {
        try {
            const response = await addExtraCharge(token,orderId,[data]);
            if (response.message === "success") {
                updateData()
                onClose()
            }
          } catch (error) {
            console.error("Failed to fetch Extra Charge:", error);
          }
    };
    
    const handleChange = (event) => {
        const { name, value } = event.target;
        setData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
    }
  return isOpen ? (
    <div className={styles.modalOverlay} onClick={onClose}>
          <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
              <p className={styles.head}>Add Extra Charges </p>
          <div className={styles.item}>
                <div>
                  <label>Charge Name</label>
                  <input type="text"   name='chargeName' onChange={handleChange} />
                </div>
                <div>
                  <label>Charge Amount</label>
                  <input type="number"  name='chargeAmount' onChange={handleChange}/>
                  </div>
                  
              </div>
             

        <div className={styles.button}>
          <button className={styles.close} onClick={onClose}>
            Close
          </button>

          <button className={styles.save} onClick={saveExtraCharges}>
            Save
          </button>
        </div>
      </div>
    </div>
  ) : (
    ""
  );
}

export default ExtraCharge;
