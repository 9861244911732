import React  from 'react';
import Layout from '../Layout/Layout';
import SelectDate from '../../orders/SelectDate';

function selectDateFlow() {


  return (
    <Layout>
    <SelectDate />
  </Layout>
  )
}

export default selectDateFlow