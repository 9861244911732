import React  from 'react';
import Layout from '../Layout/Layout';
import Category from '../../inventory/Category';

function InventoryFlow() {


  return (
<Layout>
      <Category />
    </Layout>
  )
}

export default InventoryFlow
