import React from "react";
import styles from './TermAndConditions.module.css'

const TermsAndConditions = () => {
  return (
    <div className={styles.termsContainer}>
      <h1 className={styles.title}>Terms and Conditions</h1>

      <section className={styles.section}>
        <h2 className={styles.heading}>1. Acceptance of Terms</h2>
        <p className={styles.text}>
          By downloading, installing, or using the RenterBook application (the
          "App"), you agree to comply with and be bound by these Terms and
          Conditions. If you do not agree to these terms, please refrain from
          using the App.
        </p>
      </section>

      <section className={styles.section}>
        <h2 className={styles.heading}>2. Services Provided</h2>
        <p className={styles.text}>
          RenterBook is a platform designed to facilitate the management of
          event rentals, including but not limited to inventory management,
          tender bidding, and inventory sharing. The services offered through
          the App include:
        </p>
        <ul className={styles.list}>
          <li>Inventory tracking and management</li>
          <li>Tender publishing and bidding</li>
          <li>Inventory sharing and purchasing</li>
        </ul>
        <p className={styles.text}>
          The specifics of services are subject to change without prior notice.
        </p>
      </section>

      <section className={styles.section}>
        <h2 className={styles.heading}>3. User Accounts</h2>
        <h3 className={styles.subheading}>3.1 Account Creation</h3>
        <p className={styles.text}>
          To use certain features of the App, you must create an account by
          providing accurate and complete information. You are responsible for
          maintaining the confidentiality of your login credentials.
        </p>
        <h3 className={styles.subheading}>3.2 Eligibility</h3>
        <p className={styles.text}>
          You must be at least 18 years old to use the App. By agreeing to these
          terms, you confirm that you meet the eligibility criteria.
        </p>
      </section>

      {/* Repeat sections for remaining clauses */}
      <section className={styles.section}>
        <h2 className={styles.heading}>12. Contact Us</h2>
        <p className={styles.text}>
          For any questions or concerns regarding these terms, please contact
          us at:
        </p>
        <p className={styles.text}>
          <strong>Email:</strong> [piyushlakhesar@rentalpe.co.in , voltakeydigital@gmail.com]
        </p>
        <p className={styles.text}>
          <strong>Phone:</strong> [9376195533]
        </p>
      </section>
    </div>
  );
};

export default TermsAndConditions;
