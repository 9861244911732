import React, { useState, useEffect } from "react";
import styles from "./orderStatus.module.css";
import { returnItem } from "../../api/order";
import { useSelector } from "react-redux";
import { orderDetails } from "../../api/order";

function ReturnOrder({ isOpen, onClose, orderId ,updateData }) {
  const token = useSelector((state) => state.login.token);
  const [data, setData] = useState([]);
  const [returnCart, setReturnCart] = useState({
    cartItemId: null,
    returnQuantity: 0,
    returnDate: "",
  });

  useEffect(() => {
    if (isOpen) {
      const fetchOrderDetails = async () => {
        try {
          const response = await orderDetails(token, orderId);
          setData(response.data.cartItems || []);
        } catch (error) {
          console.error("Failed to fetch order details:", error);
          setData([]);
        }
      };
      fetchOrderDetails();
    }
  }, [token, orderId, isOpen]);
  console.log(data ,returnCart);

  const handleReturnItem = async () => {
    if (
      returnCart.cartItemId &&
      returnCart.returnQuantity &&
      returnCart.returnDate
    ) {
      console.log(returnCart);
      await returnItem(token, orderId, [returnCart]);
      updateData()
      setReturnCart({
        cartItemId:null,
        returnQuantity: 0,
        returnDate: "",
      })
      
        onClose()
    } else {
      alert("Please complete all fields before saving.");
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
      setReturnCart((prev) => ({ ...prev,  [name]: name === "cartItemId" ? +value : value,}));
      
    if (name === "returnQuantity") {
      const selectedItem = data.find(
        (e) => e.cartItemId === +returnCart.cartItemId
      );
      if (selectedItem && parseInt(value, 10) > selectedItem.quantity) {
        alert(`Quantity cannot exceed ${selectedItem.quantity}`);
        setReturnCart((prev) => ({
          ...prev,
          returnQuantity: 0,
        }));
      }
      }
     
    };
    const selectedItem = data.find(
        (e) => e.cartItemId === +returnCart.cartItemId
    );
    console.log(selectedItem)

  return isOpen ? (
    <div className={styles.modalOverlay} onClick={onClose}>
      <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
        <p className={styles.head}>Return Items</p>
        <div className={styles.item}>
          <div>
            <label>Enter Item Name</label>
            <select
              name="cartItemId"
              value={returnCart.cartItemId || ''}
              onChange={handleChange}
              required
            >
              <option value="" disabled>
                Select Item
              </option>
              {data.map((item) => (
                <option key={item.cartItemId} type='number'value={item.cartItemId}>
                  {item.inventoryName}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label>Item Quantity Returned</label>
            <input
              type="number"
              name="returnQuantity"
              onChange={handleChange}
              value={returnCart.returnQuantity}
            />
          </div>
        </div>
        <div className={styles.item}>
          <div>
            <label> Return Date</label>
            <input
              type="date"
              name="returnDate"
              onChange={handleChange}
              value={returnCart.returnDate}
                          min={selectedItem?.
                            unFormattedStartDate}
                          max={selectedItem?.
                            unFormattedEndDate}
            />
          </div>
        </div>

        <div className={styles.button}>
          <button className={styles.close} onClick={onClose}>
            Close
          </button>
          <button className={styles.save} onClick={handleReturnItem}>
            Save
          </button>
        </div>
      </div>
    </div>
  ) : null;
}

export default ReturnOrder;
