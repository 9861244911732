import React from "react";
import styles from "./contact.module.css";

const ContactUs = () => {
  return (
    <div className={styles.contactContainer}>
      <h1 className={styles.title}>Contact Us</h1>
      <p className={styles.lastUpdated}>
        Last updated on 04-12-2024 22:55:50
      </p>
      <div className={styles.contactInfo}>
        <h2 className={styles.heading}>Merchant Legal Entity Name:</h2>
        <p className={styles.text}>RUPK CONNECT PRIVATE LIMITED</p>

        <h2 className={styles.heading}>Registered Address:</h2>
        <p className={styles.text}>
          A-116 Saraswati Nagar, Jaipur, Rajasthan, PIN: 302017
        </p>

        <h2 className={styles.heading}>Operational Address:</h2>
        <p className={styles.text}>
          A-116 Saraswati Nagar, Jaipur, Rajasthan, PIN: 302017
        </p>

        <h2 className={styles.heading}>Telephone No:</h2>
        <p className={styles.text}>
          <a href="tel:+919376195533" className={styles.link}>
            9376195533
          </a>
        </p>

        <h2 className={styles.heading}>E-Mail ID:</h2>
        <p className={styles.text}>
          <a href="mailto:piyushlakhesar@rentalpe.co.in" className={styles.link}>
            piyushlakhesar@rentalpe.co.in , voltakeydigital@gmail.com
          </a>
        </p>
      </div>
    </div>
  );
};

export default ContactUs;
