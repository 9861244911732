import React, { useState } from 'react';
import styles from './addGodownModal.module.css';
import save from "../../Assest/img/save.svg";
import cancel from "../../Assest/img/cancel.svg";
import { addGodown } from '../../api/inventoryAPI';
import { useSelector } from 'react-redux';
import { godownList } from '../../api/inventoryAPI';
import { toast } from 'react-toastify';

function AddGodownModal({ openModal, closeModal , updateData}) {
  const [data, setData] = useState({ name: '', address: '' });
  const token = useSelector(state => state.login.token);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSave = async () => {
    try {
   const response = await addGodown(token, data.name, data.address);
      if (response.message.trim()  === 'success') {
        updateData()
        console.log("updateData called");

        closeModal();
        toast('New Godown Successfully Added')
        setData({
          name: '',
          address:''
        }) 
      } else {
        toast(response.errorMessage || 'Failed to add godown');}
    } catch (error) {
      console.error("Error adding godown:", error);
    } 
  };

  if (!openModal) return null;

  return (
    <div className={styles.modalOverlay} onClick={closeModal}>
      <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
        <div className={styles.profile}>
          <p>Add Godown</p>
          <button className={styles.modalClose} onClick={closeModal}>X</button>
        </div>

        <div className={styles.availUnit}>
          <div>
            <label>Godown Name</label>
            <input
              type="text"
              value={data.name}
              onChange={handleChange}
              name="name"
              required
            />
          </div>
          <div>
            <label>Address</label>
            <input
              type="text"
              value={data.address}
              onChange={handleChange}
              name="address"
              required
            />
          </div>
        </div>

        <div className={styles.saveCancel}>
          <button className={styles.cancel} onClick={closeModal}>
            <img src={cancel} alt="Cancel" /> Cancel
          </button>
                  <button className={styles.save} onClick={handleSave}>
            <img src={save} alt="Save" /> Save
          </button>
        </div>
      </div>
    </div>
  );
}

export default AddGodownModal;
