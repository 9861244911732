import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import dashboardIcon from '../../Assest/img/dashbordIcon.svg';
import orderIcon from '../../Assest/img/orderIcon.svg';
import partyIcon from '../../Assest/img/partyIcon.svg';
import inventoryIcon from '../../Assest/img/inventoryIcon.svg';
import activeInventoryIcon from '../../Assest/img/activeInventoryIcon.svg';
import activeDashboardIcon from '../../Assest/img/activeDashboardIcon.svg';
import activeOrderIcon from '../../Assest/img/activeOrderIcon.svg';
import activePartyIcon from '../../Assest/img/activePartyIcon.svg';
import { sidebarAction } from "../../Reduxstore/sideBarState";
import { Link, useNavigate, useLocation } from "react-router-dom";

function Sidebar() {
    const show = useSelector((state) => state.sidebar.isSideBarOpen);
    const activeTab = useSelector((state) => state.sidebar.activeTab);
    const [hovered, setHovered] = useState([false, false, false, false]); // track hover for each item

    const dispatch = useDispatch();
    const navigate= useNavigate()
    const location = useLocation();

    useEffect(() => {
        const pathname = location.pathname;
        let tab = 'dashboard';
        
        if (pathname.startsWith('/dashboard')) tab = 'dashboard';
        if (pathname.startsWith('/order')) tab = 'order';
        if (pathname.startsWith('/party')) tab = 'party';
        if (pathname.startsWith('/category') || pathname.startsWith('/inventory')) tab = 'inventory';
        
        dispatch(sidebarAction.selectActiveTab(tab));
    }, [location.pathname, dispatch]);

    const handleActiveTab = (e, tab ,route) => {
        e.preventDefault();
        dispatch(sidebarAction.selectActiveTab(tab));
        navigate(route)
    };

    const handleMouseEnter = (index) => {
        setHovered(hovered.map((_, i) => i === index));
    };

    const handleMouseLeave = () => {
        setHovered([false, false, false, false]); // reset hover state
    };

    return (
        <aside id="sidebar" className={`sidebar my-1 ${show ? "visible" : "hidden"}`}>
            <ul className="sidebar-nav" id="sidebar-nav">

                {/* Dashboard */}
                <li
                    className={`nav-item flex justify-between items-center my-3 mx-1 p-1 rounded-lg cursor-pointer 
                        ${(hovered[0] || activeTab === 'dashboard') ? 'bg-[#600AAD] text-white' : 'text-[#600AAD]'}`}
                    onClick={(e) => handleActiveTab(e, 'dashboard','/dashboard')}
                    onMouseEnter={() => handleMouseEnter(0)}
                    onMouseLeave={handleMouseLeave}
                   
                >
                    <img
                        src={(hovered[0] || activeTab === 'dashboard') ? activeDashboardIcon : dashboardIcon}
                        alt="dashboard icon"
                        className="w-5 h-5 ml-2 mx-3 "
                    />
                    <Link className={`text-xl  dashboard ${(hovered[0] || activeTab === 'dashboard') ? 'text-white' : 'text-[#600AAD]'}`} to="/dashboard">
                        Dashboard
                    </Link>
                </li>

                {/* Order */}
                <li
                    className={`nav-item flex justify-between items-center my-3 mx-1 p-1 rounded-lg cursor-pointer 
                        ${(hovered[1] || activeTab === 'order') ? 'bg-[#600AAD] text-white' : 'text-[#600AAD]'}`}
                    onClick={(e) => handleActiveTab(e, 'order','/order')}
                    onMouseEnter={() => handleMouseEnter(1)}
                    onMouseLeave={handleMouseLeave}
                >
                    <img
                        src={(hovered[1] || activeTab === 'order') ? activeOrderIcon : orderIcon}
                        alt="order icon"
                        className="w-5 h-5 mx-3"
                    />
                    <Link className={`text-xl order ${(hovered[1] || activeTab === 'order') ? 'text-white' : 'text-[#600AAD]'}`} to="/order">
                        Order
                    </Link>
                </li>

                {/* Party */}
                <li
                    className={`nav-item flex justify-between items-center my-3 mx-1 p-1 rounded-lg cursor-pointer 
                        ${(hovered[2] || activeTab === 'party') ? 'bg-[#600AAD] text-white' : 'text-[#600AAD]'}`}
                    onClick={(e) => handleActiveTab(e, 'party','/party')}
                    onMouseEnter={() => handleMouseEnter(2)}
                    onMouseLeave={handleMouseLeave}
                >
                    <img
                        src={(hovered[2] || activeTab === 'party') ? activePartyIcon : partyIcon}
                        alt="party icon"
                        className="w-5 h-5 ml-3 mx-3 "
                    />
                    <Link className={`text-xl party ${(hovered[2] || activeTab === 'party') ? 'text-white' : 'text-[#600AAD]'}`} to="/party">
                        Party 
                    </Link>
                </li>

                {/* Inventory */}
                
                <li
                    className={`nav-item flex justify-between items-center my-3 mx-1 p-1 rounded-lg cursor-pointer 
                        ${(hovered[3] || activeTab === 'inventory') ? 'bg-[#600AAD] text-white' : 'text-[#600AAD]'}`}
                    onClick={(e) => handleActiveTab(e, 'inventory','/category')}
                    onMouseEnter={() => handleMouseEnter(3)}
                    onMouseLeave={handleMouseLeave}
                >
                    <img
                        src={(hovered[3] || activeTab === 'inventory') ? activeInventoryIcon : inventoryIcon}
                        alt="inventory icon"
                        className="w-5 h-5  ml-2 mx-3"
                    />
                    <Link className={`text-xl inventory  ${(hovered[3] || activeTab === 'inventory') ? 'text-white' : 'text-[#600AAD]'}`} to="/category">
                        Inventory
                    </Link>
                </li>
            </ul>
        </aside>
    );
}

export default Sidebar;
