import React, { useState } from 'react'
import Login from './Login'
import OtpScreen from './OtpScreen'

function LoginFlow() {

    const [mobileNumber, setMobileNumber] = useState(0);

    const updateMobileNumber = (number) => {
        setMobileNumber(number)
    }
  return (
    <div>
          {!mobileNumber ? <Login mobileNumberUpdate={updateMobileNumber} /> : <OtpScreen mobileNumberUpdate={updateMobileNumber} mobileNumber={ mobileNumber} />}
          
    </div>
  )
}

export default LoginFlow
