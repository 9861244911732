// columns.js

export const Column = [
  {
    Header: 'Item Name',
    accessor: 'inventoryName', // Accessor is the "key" in your data
  },
  {
    Header: 'Quantity',
    accessor: 'quantity',
  },
   
  {
    Header: 'Rate',
    accessor: 'dayRent',
  },
 

]
