import { createSlice } from '@reduxjs/toolkit';


const initialState = {
    partyName: '',
    startDate: '',
  endDate: '',
    partyId:'',
    addItem: []

}

const createOrderSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    partyName: (state,action) => {
          state.partyName = action.payload
          console.log(state.partyName)
    },
    partyId: (state,action) => {
      state.partyId = action.payload
      console.log(state.partyName)
},
    startDate: (state, action) => {
        state.startDate = action.payload
        console.log(state.startDate)

      },
      endDate: (state, action) => {
          state.endDate = action.payload
          console.log(state.endDate)

      },
      addItem: (state, action) => {
          state.addItem=action.payload
console.log(state.addItem)
      }
  },
});

export const creteOrderAction = createOrderSlice.actions;
export default createOrderSlice;
