import React, { useState } from "react";
import styles from "./orderStatus.module.css";
import { useSelector } from "react-redux";
import { completeOrder } from "../../api/order";
import upi from "../../Assest/img/Upi.svg";

function MarkCompleteOrder({ isOpen, onClose, orderId, updateData }) {

  const token = useSelector((state) => state.login.token);
  const [data, setData] = useState({ finalDiscount: 0, paymentMode: "" });

  const handleCompleteOrder = async () => {
    await completeOrder(token, orderId, data.paymentMode, +data.finalDiscount);
    updateData();
    onClose();
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setData((prev) => ({ ...prev, [name]: value }));
  };



  return isOpen ? (
    <div className={styles.modalOverlay} onClick={onClose}>
      <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
        <p className={styles.head}></p>

        <div className={styles.items}>
          <label>Final Discount</label>
          <input
            name="finalDiscount"
            type="number"
            onChange={handleChange}
            value={data.finalDiscount}
          ></input>
        </div>
        <div className={styles.items}>
          <label>Payment Mode</label>
    

          <select
            onChange={handleChange}
            name="paymentMode"
            value={data.paymentMode}
            required
          >
            
            <option value="" disabled>
              Select Payment Mode
            </option>
           <option value='upi'>UPI</option>
            <option  value="Cash">Cash</option>
            <option value="NetBanking">NetBanking</option>
            <option value="Wallet">Wallet</option>
          </select>
        </div>
        <div className={styles.button}>
          <button className={styles.complete} onClick={handleCompleteOrder}>
            Mark Complete
          </button>
        </div>
      </div>
    </div>
  ) : null;
}

export default MarkCompleteOrder;
