import { configureStore } from '@reduxjs/toolkit';
import LoginSlice from './login';
import sidebarSlice from './sideBarState';
import createOrderSlice from './createOrder';

const store = configureStore({
    reducer: {
        login :LoginSlice.reducer,
    sidebar: sidebarSlice.reducer,
        order:createOrderSlice.reducer
        
  },
});

export default store;
