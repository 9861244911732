import React, { useEffect, useState } from 'react';
import styles from './addGodownModal.module.css';
import save from "../../Assest/img/save.svg";
import cancel from "../../Assest/img/cancel.svg";
import { godownList } from '../../api/inventoryAPI';
import { useSelector } from 'react-redux';

function  EditGodownModal({ openModal, closeModal ,onSave , previousGodownData }) {
  const [data, setData] = useState({ godownId: previousGodownData?.godownId || "",
    godownName: previousGodownData?.godownName || "",
    totalQuantity: previousGodownData?.quantity || "",});
  const [godown, setGodown] = useState([]);
  
  const token = useSelector(state => state.login.token);
  
  useEffect(() => {
    const fetchGodown = async() => {
      const godowns =await godownList(token);
      setGodown(godowns.data)
    }
    fetchGodown()

    setData({
      godownId: previousGodownData?.godownId || "",
      godownName: previousGodownData?.godownName || "",
      totalQuantity: previousGodownData?.quantity || "",
    });
  }, [previousGodownData])
  
  const handleChange = (event) => {
    const { name, value, input } = event.target;
    if (name === 'godownId') {
      const newGodown = godown.find(e => e.godownId === +value)
      console.log(value, godown, newGodown)
      setData((prev) => ({ ...prev, [name]: +value, godownName: newGodown.godownName }));
    } else {
      setData((prev) => ({ ...prev, [name]: +value }));
    }

  };

  const handleSave = async () => {
      try {
          if (onSave) {
            closeModal()
            onSave(data)
            setData({ godownId: '', totalQuantity: '', godownName:'' });
          }
        } catch (error) {
          console.error("Error adding godown:", error);
        } 
      };
      


  if (!openModal) return null;

  return (
    <div className={styles.modalOverlay} onClick={closeModal}>
      <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
        <div className={styles.profile}>
          <p>Edit Godown</p>
          <button className={styles.modalClose} onClick={closeModal}>X</button>
        </div>

        <div className={styles.availUnit}>
          <div>
            <label>Godown Name</label>
           
             <select
              value={data.godownId}
              onChange={handleChange}
               name='godownId'
                    required
                  >
                    <option value="" disabled>Select godown</option>
                    {godown.map((godown) => (
                      <option key={godown.godownId} value={godown.godownId}>
                        {godown.godownName}
                      </option>
                    ))}
                  </select>
          </div>
          <div>
            <label>Total Quantity</label>
            <input
              type="number"
              value={data.totalQuantity}
              onChange={handleChange}
              name="totalQuantity"
              required
            />
          </div>
        </div>

        <div className={styles.saveCancel}>
          <button className={styles.cancel} onClick={closeModal}>
            <img src={cancel} alt="Cancel" /> Cancel
          </button>
                  <button className={styles.save} onClick={handleSave}>
            <img src={save} alt="Save" /> Save
          </button>
        </div>
      </div>
    </div>
  );
}

export default EditGodownModal;
