import axios from 'axios';

// Axios instance
const api = axios.create({
  baseURL: 'https://rentalpe-server.codewithabhinav.online/api', // Update with your backend URL
// baseURL: 'http://localhost:8000/api', 
  headers: {
    'Content-Type': 'application/json',
  },
});

// API Helper Function to handle requests and errors
const handleRequest = async (request) => {
  try {
    const response = await request;
    return response.data;
  } catch (error) {
    console.error('API Error:', error.response ? error.response.data : error.message);
    throw error.response ? error.response.data : { message: error.message };
  }
};

// API Call Handler
const handleApiCall = async (apiFunction, params = null) => {
  try {
    const result = params ? await apiFunction(params) : await apiFunction();
    console.log(result);
    return { success: true, data: result };
  } catch (error) {
    console.error('API call error:', error);
    return { success: false, msg: error.msg || 'An error occurred' };
  }
};


export const getOpenTenders = () => handleRequest(api.get('/tenders/tenders'));

// Bids APIs
export const submitBid = (tenderId, bidData) => handleRequest(api.post(`/bids/${tenderId}`, bidData));
export const getBidsForTender = (tenderId) => handleRequest(api.get(`/bids/${tenderId}`));
export const deleteBid = (tenderId, bidId) => handleRequest(api.delete(`/bids/${tenderId}/bids/${bidId}`));



// Example: Handle Get Open Tenders
export const handleGetOpenTenders = async () => {
  return handleApiCall(getOpenTenders);
};



// Example: Handle Submit Bid
export const handleSubmitBid = async (tenderId, bidData) => {
  return handleApiCall(() => submitBid(tenderId, bidData)); // Correctly pass bidData
};

// Example: Handle Get Bids for Tender
export const handleGetBidsForTender = async (tenderId) => {
  return handleApiCall(getBidsForTender, tenderId);
};

// Example: Handle Delete Bid
export const handleDeleteBid = async (tenderId, bidId) => {
  return handleApiCall(deleteBid, { tenderId, bidId });
};
