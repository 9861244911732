import React from 'react';
import styles from './Challan.module.css';

function Challan({ isOpen, onClose, data }) {
    if (!isOpen) return null;

    return (
        <div className={styles.modalOverlay} onClick={onClose}>
            <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
                <div className={styles.header}>
                    <h2>Challan</h2>
                    <button className={styles.closeButton} onClick={onClose}>X</button>
                </div>
                
                <div className={styles.storeInfo}>
                    <div>

                    <span>Store Name</span>
                    <p>hello@email.com</p>
                    <p>+91 0000000000</p>
                   
                    </div>

                <div >
                    <span>address</span>
                <p>City, State, IN - 000 000</p>
                <p>GST Number: XXXXXXXX1234XXXX</p>
                </div>
                </div>
                <div className={styles.challanDetails}>
                    <div>
                        <strong>Billed to</strong>
                        <p>Customer Name</p>
                        <p>customer@email.com</p>
                        <p>+0 (000) 123-4567</p>
                        <p>City, Country - 00000</p>
                    </div>
                    <div>
                        <strong>Challan number:</strong> #AB2324-01
                        <p>State Code: 302004</p>
                        <p>Challan date: 01 October, 2024</p>
                        <p>Due date: 12 October, 2024</p>
                    </div>
                </div>

                <table className={styles.table}>
                    <thead>
                        <tr>
                            <th>S No.</th>
                            <th>Item Detail</th>
                            <th>QTY</th>
                            <th>UNIT</th>
                        </tr>
                    </thead>
                    <tbody>
                        {/* {data.map((item, index) => (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{item.name}</td>
                                <td>{item.quantity}</td>
                                <td>{item.unit}</td>
                            </tr>
                        ))} */}
                    </tbody>
                </table>

                <button className={styles.downloadButton}>Download</button>
            </div>
        </div>
    );
}

export default Challan;
