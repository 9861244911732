import React  from 'react';
import Layout from '../Layout/Layout';
import SingleInventoryDetail from '../../inventory/SingleInventoryDetail';

function SingleInventoryFlow() {


  return (
    <Layout>
      <SingleInventoryDetail />
    </Layout>
  )
}

export default SingleInventoryFlow
