import React  from 'react';
import Layout from '../Layout/Layout';
import Party from '../../party/Party';

function PartyFlow() {


  return (
    <Layout>
      <Party />
    </Layout>
  )
}

export default PartyFlow
