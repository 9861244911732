import { createSlice } from '@reduxjs/toolkit';

const initialSideBar = () => window.innerWidth >= 746;

// Add a mapping function to determine active tab from route
const getTabFromRoute = (pathname) => {
  if (pathname.startsWith('/dashboard')) return 'dashboard';
  if (pathname.startsWith('/order')) return 'order';
  if (pathname.startsWith('/party')) return 'party';
  if (pathname.startsWith('/category') || pathname.startsWith('/inventory')) return 'inventory';
  return 'dashboard'; // default tab
};

const initialState = {
  isSideBarOpen: initialSideBar(),
  activeTab: getTabFromRoute(window.location.pathname) // Set initial active tab based on route
};

const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    toggleSidebar: (state) => {
      state.isSideBarOpen = !state.isSideBarOpen;
    },
    selectActiveTab: (state, action) => {
      state.activeTab = action.payload
    }
  },
});

export const sidebarAction = sidebarSlice.actions;
export default sidebarSlice;
