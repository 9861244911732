import React from 'react';
import Navbar from '../../Home/Navbar';
import Sidebar from '../../Home/SideBar';
import BottomNav from '../../Home/BottomNav';

function Layout({ children }) {
  return (
    <div>
      <Navbar />
      <div className='bottom'>
        <BottomNav/>
        <Sidebar />
        <div className='main-container'>
          {children}
        </div>
      </div>
    </div>
  );
}

export default Layout;
