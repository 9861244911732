import React, { useState, useEffect } from "react";
import Back from "../../Assest/img/btn-back.svg";
import img from "../../Assest/img/inventory-img.svg";
import "./singleInventory.css";
import edit from "../../Assest/img/edit.svg";
import deleteImg from "../../Assest/img/deleteImg.svg";
import { useNavigate } from "react-router";
import { inventoryDetail } from "../../api/inventoryAPI";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import defaultImg from "../../Assest/img/defaultImg.svg";

function SingleInventoryDetail() {
  const navigate = useNavigate();
  const token = useSelector((state) => state.login.token);
  const location = useLocation();
  const [data, setData] = useState({
    name: "",
    purchasePrice: "",
    availableQuantity: "",
    totalQuantity: "",
    categoryName: "",
    godownAddition: "",
    dayRent: "",
    discount: "",
    img: "",
    description: "",
  });

  const queryParams = new URLSearchParams(location.search);

  const inventoryId = queryParams.get("inventoryId");
  // console.log(data.godownAddition.map(e=>e))
  useEffect(() => {
    const fetchInventoryDetail = async () => {
      if (!inventoryId) {
        console.error("No inventoryId provided");
        return;
      }

      try {
        const response = await inventoryDetail(token, inventoryId);
        setData({
          name: response.data.name,
          purchasePrice: response.data.purchasedPrice,
          availableQuantity: response.data.availableQuantity,
          totalQuantity: response.data.totalQuantity,
          categoryName: response.data.categoryName,
          godownAddition: response.data.godownAddition,
          dayRent: response.data.dayRent,
          discount: response.data.discount,
          description: response.data.description,
          img: response.data.img,
        });

        console.log(response.data);
      } catch (error) {
        console.error("Failed to fetch category list:", error);
      }
    };

    fetchInventoryDetail();
  }, [token]);

  const editInventory = () => {
    navigate("/editinventory", {
      state: { inventoryId: inventoryId, inventoryList: data },
    });
  };



  return (
    <div className="inventoryDetail-container">
      <div className="main">
        <div className="mobile">
          <div className="head">
            <p>{data.name}</p>
            <div className="edit-delete">
              <button className="edit" onClick={editInventory}>
                <img src={edit}></img>Edit
              </button>
              <button className="delete">
                <img src={deleteImg}></img>Delete
              </button>
            </div>
          </div>
          <div className="info">
            <p className="price">
              Price : <span>Rs {data.dayRent}/day</span>
            </p>
            {data.discount && <p className="discount">{data.discount}off</p>}
          </div>
        </div>
        <div className="left">
          <img src={data.img ? data.img : defaultImg} />
        </div>
        <div className="right">
          <div className="card">
            <div className="web">
              <div className="head">
                <p>{data.name}</p>
                <div className="edit-delete">
                  <button className="edit" onClick={editInventory}>
                    <img src={edit}></img>Edit
                  </button>
                  <button className="delete">
                    <img src={deleteImg}></img>Delete
                  </button>
                </div>
              </div>
              <div className="info">
                <p className="price">
                  Price : <span>Rs {data.dayRent}/day</span>
                </p>
                {data.discount && (
                  <p className="discount">{data.discount}off</p>
                )}
              </div>
            </div>
            <div className="text">
              <p>{data.description}</p>
            </div>
            <div className="item-detail">
              <div className="left-detail">
                <p>Item Number</p>
                <p>Purchase Price</p>
                <p>Available</p>
                <p>Unit</p>
                <p>Category</p>
                <p>Godown</p>
              </div>
              <div className="right-detail">
                <p>{data.name}</p>
                <p>{data.purchasePrice}/-</p>
                <p>{data.availableQuantity}</p>
                <p>{data.totalQuantity}</p>
                <p>{data.categoryName}</p>
                <p></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SingleInventoryDetail;
