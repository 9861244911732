import React from 'react'
import styles from './orderStatus.module.css';
import redCross from '../../Assest/img/redCross.svg';
import { useNavigate } from 'react-router';

function OrderStatusIncompleteModal({ isOpen, onClose }) {

    const navigate = useNavigate()
    if (!isOpen) return null;


    const handleAgainCreateOrder = () => {
        onClose()
        navigate('/order/selectDate')
    }

  return (
    <div className={styles.modalOverlay} onClick={onClose}>
          <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
              
              <img   className={styles.img}src={redCross}></img>
              <p className={styles.text1}>  Order has been not Created . Please Try Again</p>
              <button  className={styles.modalClose1} onClick={handleAgainCreateOrder}> Okay</button>
          </div>
          
    </div>
  )
}

export default OrderStatusIncompleteModal
