import React from "react";

const PrivacyPolicy = () => {
  const styles = {
    container: {
      padding: "20px",
      fontFamily: "Arial, sans-serif",
      lineHeight: "1.6",
      color: "#333",
      maxHeight: "100vh", // Limits the container height to the viewport height
      overflowY: "auto",
    },
    heading1: {
      fontSize: "2em",
      fontWeight: "bold",
      marginBottom: "20px",
      color: "#004085",
    },
    heading2: {
      fontSize: "1.5em",
      fontWeight: "bold",
      marginTop: "20px",
      marginBottom: "10px",
      color: "#0056b3",
    },
    heading3: {
      fontSize: "1.2em",
      fontWeight: "bold",
      marginTop: "15px",
      color: "#0062cc",
    },
    paragraph: {
      marginBottom: "15px",
      fontSize: "1em",
      lineHeight: "1.8",
    },
    list: {
      marginBottom: "15px",
      paddingLeft: "20px",
    },
    listItem: {
      marginBottom: "10px",
    },
    address: {
      fontStyle: "normal",
      marginTop: "15px",
    },
    link: {
      color: "#007bff",
      textDecoration: "none",
    },
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.heading1}>Privacy Policy</h1>

      <h2 style={styles.heading2}>Introduction</h2>
      <p style={styles.paragraph}>
        Welcome to RenterBook. We are committed to safeguarding your privacy.
        This Privacy Policy outlines how we collect, use, store, and protect
        your data. By using our services, you agree to the practices described
        in this policy.
      </p>
      <p>
        These Terms of Service apply to all users of the Mobile Application,
        Store, Desktop Application, Website, platform and any services provided
        by RenterBook, except as may be specifically agreed otherwise in
        writing.
      </p>
      <p>
        “Services” means the invoicing, business and accounting management,
        online store services provided by RenterBook to an User through the
        Mobile Application, Desktop Application, Website, Store and any other
        RenterBook Products from time to time;
      </p>
      <h2 style={styles.heading2}>Data Collection</h2>
      <p style={styles.paragraph}>
        We collect personal information that you voluntarily provide to us when
        you register on our website, express an interest in obtaining
        information about us or our products and services, or otherwise contact
        us.
      </p>
      <h3 style={styles.heading3}>Types of Data Collected:</h3>
      <ul style={styles.list}>
        <li style={styles.listItem}>
          Personal Identification Information (e.g., name, email address, phone
          number)
        </li>
        <li style={styles.listItem}>
          Technical Data (e.g., IP address, browser type, operating system)
        </li>
      </ul>

      <h2 style={styles.heading2}>Use of Data</h2>
      <p style={styles.paragraph}>
        The data we collect is solely for the purpose of providing and improving
        our services to you. We do not use your data for any other purposes,
        including but not limited to marketing, advertising, or any other work
        unrelated to the services we provide.
      </p>

      <h2 style={styles.heading2}>Acceptance of the Terms of Service</h2>
      <p style={styles.paragraph}>
        Your data is stored securely on our servers. We implement a variety of
        security measures to maintain the safety of your personal information.
        Access to your data is restricted to authorized personnel only. The User
        agrees that the User shall be bound by these Terms by accessing or using
        the RenterBook Products, or by otherwise availing of the Services from
        RenterBook. If the User does not accept these Terms of Service, the User
        shall not open an account for availing of the Services or using the
        RenterBook Products.{" "}
      </p>
      <h2 style={styles.heading2}>Data Storage</h2>
      <ul style={styles.list}>
        <li style={styles.listItem}>
          1. Your data is stored securely on our servers. We implement a variety
          of security measures to maintain the safety of your personal
          information. Access to your data is restricted to authorized personnel
          only.
        </li>
        <li>
          2. For the User’s use of the RenterBook Products and Services,
          RenterBook grants the User a limited, non-exclusive, non-transferable
          right to install and / or use the RenterBook Products. However, the
          User shall not copy the RenterBook Products or any of its components.
        </li>
        <li>
          {" "}
          3. RenterBook also grants the User a non-exclusive, non-transferable
          license to access such content on the RenterBook Products which is
          owned by RenterBook. For using any content owned by a third party
          Person, the User still require a license from such third party Person
          (including, without limitation, the Software Providers), and
          RenterBook does not license such content to the User and the User’s
          use of content owned by a third party Person, including any content
          owned by the Software Providers, is governed by applicable terms and
          conditions prescribed by such third party Person.
        </li>
      </ul>

      <h2 style={styles.heading2}> Conditions for use</h2>
      <ul style={styles.list}>
        <li style={styles.listItem}>
          1. In case RenterBook alters or amends the eligibility criteria to be
          registered for receiving the Services and the User no longer complies
          with the new eligibility criteria, as determined by RenterBook in its
          sole discretion, the User accepts that RenterBook may restrict or
          suspend the access to the Services without any liability for
          RenterBook. The User is solely responsible for ensuring that the User
          are in compliance with these Terms of Services
        </li>{" "}
        <li style={styles.listItem}>
          2. Please note that the RenterBook Products, will be also subject to
          the Additional Terms and the terms of use of the Software, as
          applicable. If there is any conflict between the Additional Terms, the
          terms of use of the Software, and these Terms of Service with respect
          to a User’s use of the RenterBook Products, then, these Terms of
          Service shall prevail.
        </li>{" "}
        <li style={styles.listItem}>
          3. The User agrees that the User shall, as required or instructed by
          RenterBook, update the RenterBook Products (if so required) and / or
          any device to operate the RenterBook Products, as may be instructed by
          RenterBook.
        </li>{" "}
        <li style={styles.listItem}>
          4. The User shall co-operate with RenterBook and its officers,
          representatives, directors and agents and render assistance to the
          RenterBook in integration of the RenterBook Products, if so required
          by RenterBook, for the purpose of enabling and providing the Services.
        </li>{" "}
        <li style={styles.listItem}>
          5. The User shall ensure that there are proper security measures at
          its respective desktop, mobile device or other device used to access
          the RenterBook Products to prevent any hacking and the User accepts
          all liabilities with respect to any compromise or unauthorized use or
          disclosure or access to such information.
        </li>
      </ul>
      <h3 style={styles.heading3}>Security Measures:</h3>
      <ul style={styles.list}>
        <li style={styles.listItem}>
          Encryption of data in transit and at rest
        </li>
        <li style={styles.listItem}>Regular security audits and assessments</li>
        <li style={styles.listItem}>
          Secure access controls and authentication mechanisms
        </li>
      </ul>
      <p style={styles.paragraph}>
        When you log in to your account using our SaaS application, all your
        account information and data are stored with complete security. Your
        data is fully protected and your personal information is not used for
        any other purposes.
      </p>

      <h2 style={styles.heading2}>Data Sharing and Disclosure</h2>
      <p style={styles.paragraph}>
        We do not share, sell, trade, or otherwise transfer your personal data
        to outside parties. This includes third-party service providers, except
        as necessary to provide our services (e.g., payment processors) and as
        required by law.
      </p>

      <h2 style={styles.heading2}>Data Retention</h2>
      <p style={styles.paragraph}>
        We retain your personal information only for as long as is necessary for
        the purposes set out in this Privacy Policy. Once your data is no longer
        needed, we will securely delete or anonymize it.
      </p>

      <h2 style={styles.heading2}>Your Data Rights</h2>
      <p style={styles.paragraph}>You have the right to:</p>
      <ul style={styles.list}>
        <li style={styles.listItem}>Access your personal data</li>
        <li style={styles.listItem}>Correct any inaccuracies in your data</li>
        <li style={styles.listItem}>Request the deletion of your data</li>
        <li style={styles.listItem}>
          Object to or restrict the processing of your data
        </li>
        <li style={styles.listItem}>
          Withdraw your consent at any time, where consent is the legal basis
          for processing
        </li>
      </ul>
      <p style={styles.paragraph}>
        To exercise these rights, please contact us at [contact information].
      </p>

      <h2 style={styles.heading2}>Changes to This Privacy Policy</h2>
      <p style={styles.paragraph}>
        We may update this Privacy Policy from time to time to reflect changes
        in our practices or relevant laws. We will notify you of any significant
        changes by posting the new Privacy Policy on our website and updating
        the effective date.
      </p>

      <h2 style={styles.heading2}>Contact Us</h2>
      <p style={styles.paragraph}>
        If you have any questions or concerns about this Privacy Policy or our
        data practices, please contact us at:
      </p>
      <address style={styles.address}>
        RenterBook
        <br />
        Jaipur, Rajasthan, 302017
        <br />
        <a href="mailto:voltakeydigital@gmail.com" style={styles.link}>
          piyushlakhesar@rentalpe.co.in
        </a>
        <br />
        +91 9376195533
      </address>
      <p style={styles.paragraph}>
        Thank you for trusting RenterBook with your data. We are dedicated to
        protecting your privacy and ensuring your data's security.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
