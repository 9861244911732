import React from 'react';
import InventoryFlow from './Components/pages/inventorypage/inventoryFlow';
import CategoryFlow from './Components/pages/inventorypage/categoryFlow'
import SingleInventoryFlow from './Components/pages/inventorypage/singleInventoryFlow';
import EditInventoryFlow from './Components/pages/inventorypage/editInventoryFlow';
import PartyFlow from './Components/pages/partypages/partyFlow';
import AddPartyFlow from './Components/pages/partypages/AddPartyFlow';
import AddInventoryFlow from './Components/pages/inventorypage/addInventoryFlow';
import OrderFlow from './Components/pages/orderPages/orderFlow';
import SelectDateFlow from './Components/pages/orderPages/selectDateFlow';
import AdditemFlow from './Components/pages/orderPages/addItemFlow';
import ProfileFlow from './Components/pages/profile/profileFlow';
import EditProfileFlow from './Components/pages/profile/editProfileFlow';
import HomePageFlow from './Components/pages/Layout/HomePageFlow';
import Allvendor from './RentalPeApi/RentalPePages/AllTenders/All Tenders/Alltenders';
import TenderDetails from './RentalPeApi/RentalPePages/TenderDetails/Tenderdetails/Tenderdetails';
import EditCreateOrderFlow from './Components/pages/orderPages/editCreateOrderFlow';
import CreateOrderFlow from './Components/pages/orderPages/createOrderFlow';
import OrderDetailFlow from './Components/pages/orderPages/OrderDetailFlow';
import SubscriptionFlow from './Components/pages/subscription/SubscriptionFlow';



const PrivateRaoute = [
  //dashboard
  { path: "/dashboard", element: <HomePageFlow />, private: true },
  { path: "/dashboard/subscription", element: <SubscriptionFlow />, private: true },


  // inventory
  { path: "/category", element: <CategoryFlow />, private: true },
  { path: "/inventoryDetail", element: <InventoryFlow />, private: true },
  { path: "/editinventory", element: <EditInventoryFlow />, private: true },
  { path: "/singleinventory", element: <SingleInventoryFlow />, private: true },
  { path: "/addinventory", element: <AddInventoryFlow />, private: true },

  // party
  { path: "/party", element: <PartyFlow />, private: true },
  { path: "/addparty", element: <AddPartyFlow />, private: true },

  //profile
  { path: "/profile", element: <ProfileFlow />, private: true },
  { path: "/profile/editProfile", element: <EditProfileFlow />, private: true },
  
  //tenders and bid
  { path: "/Alltenders", element: <Allvendor />, private: true },
  { path: "/tender/:id", element: <TenderDetails />, private: true },


  //order
  { path: "/order", element: <OrderFlow />, private: true },
  { path: "/order/createOrder", element: <CreateOrderFlow />, private: true },
  { path: "/order/selectDate", element: <SelectDateFlow />, private: true },
  { path: "/order/addItem", element: <AdditemFlow />, private: true },
  { path: "/order/editCreateOrder", element: <EditCreateOrderFlow />, private: true, },
  {path: "/order/orderDetail", element: <OrderDetailFlow/>,private: true,  },

];

export default PrivateRaoute;
