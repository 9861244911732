import React from 'react'
import styles from './orderStatus.module.css';
import green from '../../Assest/img/green.svg';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { orderList } from '../../api/order';

function OrderStatusCompleteModal({ isOpen, onClose }) {

  const navigate = useNavigate();
  const token = useSelector((state) => state.login.token);
  
  
  const handleCreateOrderSuccessFully = async() => {
    onClose()
    navigate('/order')
    await orderList(token);
  }
  if (!isOpen) return null;
  return (
    <div className={styles.modalOverlay} onClick={onClose}>
          <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
              
              <img  className={styles.img}src={green}></img>
              <p className={styles.text}>  Your Order Has Been Confirmed Successfully</p>
              <button  className={styles.modalClose} onClick={handleCreateOrderSuccessFully}> Okay</button>
          </div>
          
    </div>
  )
}

export default OrderStatusCompleteModal
