import React, { useEffect } from 'react';
import styles from './subscription.module.css';
import { useSelector } from 'react-redux';
import { subscriptionDetails } from '../../api/login';
import { useState } from 'react';
import MoreFeatures from './MoreFeatures';
function Subscription() {
  const token = useSelector((state) => state.login.token);
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  
  useEffect(() => {
    const fetchSubscriptionDetails = async () => {
      try {
        const res = await subscriptionDetails(token);
        setSubscriptionPlans(res.data);
        console.log(subscriptionPlans); 
      } catch (error) {
        console.error('Error fetching subscription details:', error); // Handle errors
      }
    };

    fetchSubscriptionDetails();
  }, [token]); 
  console.log(subscriptionPlans); 

  return (
    <div className={styles.container}>
      <div className={styles.cardContainer}>
        <div className={styles.header}>
          <h1>Plans & Pricing</h1>
        </div>
        <div className={styles.planSelect}>

          <p>Choose a plan that suits for your business</p>
          </div>
        <div className={styles.planSelection}>
          <div>
            <select>
              <option>1 Year</option>
              <option>2 Years</option>
            </select>
            <select>
              <option>Desktop + Mobile</option>
              <option>Desktop Only</option>
            </select>
          </div>
        </div>
        <div className={styles.plansContainer}>
        {subscriptionPlans.map((plan, index) => (
  <div key={index} className={`${styles.planCard} ${styles[plan.color]}`}>
    <div className={styles.planInfo}>
      <span className={styles.duration}>{plan.duration} Months</span>
      <span className={styles.device}>Desktop + Mobile</span>
    </div>
    <div className={styles.planHeader}>
    <div className={styles.planIcon}> {plan.color ==='silver' ?  '🥈' : '👑'}</div> 

      <div className={styles.planType}>{plan.name}</div>
      <div className={styles.planPrice}>₹ {plan.price}</div>
    </div>
    <button className={styles.buyButton}>Buy {plan.name}</button>
    <div className={styles.features}>
      {plan.features.slice(0,4).map((feature, featureIndex) => (
        <div key={featureIndex} className={styles.feature}>
          <span className={styles.checkmark}>✓</span>
          {feature}
        </div>
      ))}
              {plan.features.length > 4 && (
                <div className={styles.featureButton}>
                  <button onClick={() => {
                    setSelectedPlan(plan);
                    setIsModalVisible(true);
                  }}>
                    +{plan.features.length - 4} More Features
                  </button>
                </div>
              )}
    </div>
  </div>
))}

          {/* <div className={`${styles.planCard} ${styles.silver}`}>
            <div className={styles.planInfo}>
              <span className={styles.duration}>1 Year</span>
              <span className={styles.device}>Desktop + Mobile</span>
            </div>
            
            <div className={styles.planHeader}>
              <div className={styles.planIcon}>🥈</div>
              <div className={styles.planType}>Silver Plan</div>
              <div className={styles.planPrice}>₹ 3599.00</div>
            </div>
            
            <button className={styles.buyButton}>Buy Silver</button>
            
            <div className={styles.features}>
              <div className={styles.feature}>
                <span className={styles.checkmark}>✓</span>
                Print data across devices
              </div>
              <div className={styles.feature}>
                <span className={styles.checkmark}>✓</span>
                Create multiple companies (2 companies)
              </div>
              <div className={styles.feature}>
                <span className={styles.checkmark}>✓</span>
                Download data (Up to 2GB storage)
              </div>
              <div className={styles.feature}>
                <span className={styles.checkmark}>✓</span>
                Quick billing
              </div>
              <div className={styles.feature}>
                <span className={styles.checkmark}>✓</span>
                Manage products & maintain stock
              </div>
              <div className={styles.feature}>
                <span className={styles.checkmark}>✓</span>
                Create multiple users (3 users)
              </div>
              <div className={styles.feature}>
                <span className={styles.checkmark}>✓</span>
                Quick data download
              </div>
            </div>
          </div>

          {/* <div className={`${styles.planCard} ${styles.gold}`}>
            <div className={styles.planInfo}>
              <span className={styles.duration}>1 Year</span>
              <span className={styles.device}>Desktop + Mobile</span>
            </div>
            
            <div className={styles.planHeader}>
              <div className={styles.planIcon}>👑</div>
              <div className={styles.planType}>Gold Plan</div>
              <div className={styles.planPrice}>₹ 3999.00</div>
            </div>
            
            <button className={styles.buyButton1}>Buy Gold</button>
            
            <div className={styles.features}>
              <div className={styles.feature}>
                <span className={styles.checkmark}>✓</span>
                Print data across devices
              </div>
              <div className={styles.feature}>
                <span className={styles.checkmark}>✓</span>
                Create multiple companies (5 companies)
              </div>
              <div className={styles.feature}>
                <span className={styles.checkmark}>✓</span>
                Download data (Up to 5GB storage)
              </div>
              <div className={styles.feature}>
                <span className={styles.checkmark}>✓</span>
                Quick billing
              </div>
              <div className={styles.feature}>
                <span className={styles.checkmark}>✓</span>
                Manage products & maintain stock
              </div>
              <div className={styles.feature}>
                <span className={styles.checkmark}>✓</span>
                Add 15+ employees
              </div>
              <div className={styles.feature}>
                <span className={styles.checkmark}>✓</span>
                Bulk data process
              </div>
            </div>
          </div> */}
        </div>
      </div>
      <MoreFeatures
        isVisible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        plan={selectedPlan}
      
      />
    </div>
  );
}

export default Subscription; 