import React from 'react';
import styles from './MoreFeatures.module.css';
import { subscriptionPlansBuy } from '../../api/login';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';


const MoreFeatures = ({ isVisible, onClose, plan }) => {
  const token = useSelector((state) => state.login.token);
  if (!isVisible) return null;

  console.log(plan)
  const buyPlan = async () => {
    try {
     
      await subscriptionPlansBuy(token, plan.id);
      console.log(plan.id)
    } catch {
      
    }
};

  return (
    <div className={styles.modalOverlay} onClick={onClose}>
      <div className={styles.modalContainer} onClick={(e) => e.stopPropagation()}>
        <button className={styles.closeButton} onClick={onClose}>
          ×
        </button>
        
        <div className={styles.modalTitle}>
          {plan.name} Features and Details
        </div>

        <div className={styles.modalContent}>
          {plan.features.map((feature, index) => (
           
            <div className={styles.featureDetail}>{feature}           <span className={styles.checkmark}>✓</span>
</div>
            
          ))}
        </div>

        <button 
          className={styles.buyButton}
          onClick={buyPlan}
        >
          Buy {plan.name}
        </button>
      </div>
    </div>
  );
};

export default MoreFeatures;
