import React ,{useState , useEffect} from "react";
import styles from "./detailPartyModal.module.css";
import edit from '../../Assest/img/edit.svg';
import deleteImg from '../../Assest/img/deleteImg.svg';
import { partyDetail } from "../../api/party";
import { useSelector } from "react-redux";
import defaultImg from '../../Assest/img/defaultImg.svg'

function DetailPartyModal({ isOpen, onClose, openEditModal ,partyId}) {
  const [cardData, setCardData] = useState([]);

  
  const token = useSelector(state => state.login.token);

  useEffect(() => {
    const fetchPartyDetail = async () => {
      try {
        const response = await partyDetail(token ,partyId);
        if (response.message === 'success') {
          setCardData(response.data);
          console.log(response.data)
        }
      } catch (error) {
          console.error("Failed to fetch category list:", error);
        }
  };
  
  fetchPartyDetail()
}, [token])

const handleEditClick = () => {
    openEditModal(); // Open the edit modal
  }
  if (!isOpen) return null;

  return (
    <div className={styles.modalOverlay} onClick={onClose}>
      <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
        <div className={styles.head}>
          <div className={styles.profile}>
            <img src={defaultImg} alt="Profile" />
            <p>Lisa Edney</p>
          </div>
          <div>
            <button className={styles.modalClose} onClick={onClose}>X</button>
          </div>
        </div>
        <hr/>
        <div className={styles.info}></div>
        <div className={styles.box}>
          <div className={styles.left}>Name</div>
          <div className={`${styles.right} ${styles.name}`}>{cardData.name}</div>
        </div>
        <div className={styles.box}>
          <div className={styles.left}>Phone</div>
        <div className={styles.right}>{cardData.phone}</div>
        </div>
        <div className={styles.box}>
          <div className={styles.left}>Email</div>
        <div className={styles.right}>{cardData.email}</div>
        </div>
        <div className={styles.box}>
          <div className={styles.left}>Address</div>
        <div className={styles.right}>{cardData.address}</div>
        </div>
        <div className={styles.box}>
          <div className={styles.left}>GST</div>
        <div className={styles.right}>{cardData.gst}</div>
        </div>
        <div className={styles.box}>
          <div className={styles.left}>State</div>
        <div className={styles.right}>{cardData.state}</div>
        </div>
        <div className={styles.box}>
          <div className={styles.left}>city</div>
        <div className={styles.right}>{cardData.city}</div>
        </div>
        <hr/>
      <div className={styles.editDelete}>
                
        <button className={styles.edit} onClick={handleEditClick} ><img src={edit}></img>Edit</button>
            <button className={styles.delete}><img src={deleteImg}></img>Delete</button>
</div>
      </div>
    </div >
    
  );
}

export default DetailPartyModal;
