import React, { useEffect, useState, useRef } from "react";
import "./otp.css";
import goBack from "../../Assest/img/goBack.png";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loginAction } from "../../Reduxstore/login";
import loginSideScreen from "../../Assest/img/loginSideScreen.svg";
import logo from "../../Assest/img/logo.svg";
import welcome from "../../Assest/img/welcome.svg";
import { fetchOtp, login, fetchProfile } from "../../api/login";
import SignUp from "./SignUp";
import { Link } from "react-router-dom";

function OtpScreen({ mobileNumber, mobileNumberUpdate }) {
    const [timeLeft, setTimeLeft] = useState(30);
    const [disabledButton, setButtonDisabled] = useState(true);
    const [otp, setOtp] = useState(new Array(4).fill(""));
    const [renderSignUP, SetRenderSignUp] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const otpRefs = useRef([]);

    useEffect(() => {
        // Focus the first input when the component mounts
        if (!otp[0].length) {
            otpRefs.current[0]?.focus();
        }

        //active resend button
        if (timeLeft === 0) {
            setButtonDisabled(false);
        }

        // Timer logic
        const interval = setInterval(() => {
            setTimeLeft((prevTime) => {
                if (prevTime <= 1) {
                    clearInterval(interval);
                    navigate("/");
                    return 0;
                }
                return prevTime - 1;
            });
        }, 1000);

        return () => clearInterval(interval);
    }, [navigate, timeLeft]);

    const goLoginPage = () => {
        mobileNumberUpdate(0);
    };

    const handleChange = (event, index) => {
        const value = event.target.value;

        if (/[^0-9]/.test(value)) return; // Allow only numbers

        const updatedOtp = [...otp];
        updatedOtp[index] = value;
        setOtp(updatedOtp);

        // Focus on the next input field
        if (value && otpRefs.current[index + 1]) {
            otpRefs.current[index + 1].focus();
        }
    };

    const handleKeyDown = (event, index) => {
        if (
            event.key === "Backspace" &&
            !otp[index] &&
            otpRefs.current[index - 1]
        ) {
            otpRefs.current[index - 1].focus();
        }
    };

    const handleOtp = async () => {
        const otpNumber = otp.join("");
        const phone = mobileNumber;
        console.log(mobileNumber);
        if (otp.join("")) {
            const otpResult = await fetchOtp(otpNumber, phone);
            dispatch(loginAction.login());
            dispatch(loginAction.token(otpResult.data.token));
            localStorage.setItem("token", otpResult.data.token);
            if (otpResult.message === "success") {
                await fetchProfile(otpResult.data.token);
                if (otpResult.data.newUser) {
                    SetRenderSignUp(true)
                }

          
            }
        }
    };

    const handleResendOTP = async () => {
        await login(mobileNumber);
        setTimeLeft(30);
        setButtonDisabled(true);
    };

    return (
        <>
      {renderSignUP ? <SignUp/> : ( <div className="otp-main-container d-flex justify-content-center align-items-center">
            <div className="otp-left-screen">
                <img src={loginSideScreen} alt="Left screen" />
            </div>
            <div className="otp-right-screen">
                <div className="otp-form-container">
                    <div className="otp-head">
                        <div className="go-login">
                            <img src={goBack} alt="Go to login" onClick={goLoginPage} />
                            <h4>OTP Verification</h4>
                        </div>
                    </div>
                    <div className="otp-web-img">
                        <img className="logo" src={logo} alt="Logo" />
                        <img className="welcome" src={welcome} alt="Welcome" />
                    </div>
                    <div className="otp-head">
                        <p className="otp-head-text">We have sent a code to</p>
                        <p className="otp-head-number">Verify OTP sent to {mobileNumber}</p>
                        <div className="otp-container">
                            {otp.map((data, index) => (
                                <input
                                    className="otp-box"
                                    type="text"
                                    maxLength="1"
                                    key={index}
                                    ref={(el) => (otpRefs.current[index] = el)}
                                    value={data}
                                    onChange={(e) => handleChange(e, index)}
                                    onKeyDown={(e) => handleKeyDown(e, index)}
                                    onFocus={(e) => e.target.select()}
                                />
                            ))}
                        </div>
                        <div className="otp-time">
                           <div><button className="otp-edit-btn">Edit Number</button></div> 
                            <div className="otp-timer">
                                <button disabled={disabledButton} onClick={handleResendOTP}>
                                    Resend OTP
                                </button>
                                {timeLeft === 0 ? "" : <span>{timeLeft}</span>}
                            </div>
                        </div>
                    </div>
                    <div className="otp-text">
                            <p className="otp-agree-text">By continuing, you agree to our 
                            <Link to='/privacyPolicy' className="policy"> Privacy policy</Link> &
                                <Link to='/termAndCondition' className="policy"> Terms & Conditions</Link>  &
                                <Link to='/contactUs' className="policy"> Contact Us</Link>
                        </p>
                    </div>
                    <div className="btn-otp">
                        <button
                            className="btn"
                            disabled={otp.join("").length < 4}
                            onClick={handleOtp}
                        >
                            Send OTP
                        </button>
                        </div>
                        <p className="policy-text"> By Continue you agree to our
                            <Link to='/privacyPolicy' className="policy"> Privacy policy</Link> &
                              <Link to='/termAndCondition' className="policy"> Terms & Conditions</Link> &
                          <Link to='/contactUs' className="policy"> Contact Us</Link></p>
                </div>
            </div>
        </div>)}

        </>
  )

}

export default OtpScreen;
