import React  from 'react';
import Layout from '../Layout/Layout';
import InventoryDetail from '../../inventory/InventoryDetail';

function InventoryFlow() {


  return (
    <Layout>
    <InventoryDetail />
  </Layout>
  )
}

export default InventoryFlow
