import React, { useState, useEffect } from 'react';
import styles from './editParty.module.css';
import save from "../../Assest/img/save.svg";
import cancel from "../../Assest/img/cancel.svg";
import { editParty, partyDetail } from '../../api/party';
import { useSelector } from 'react-redux';
import defaultImg from '../../Assest/img/defaultImg.svg'

function EditParty({ openEditModal, closeEditModal, partyId ,updateProfile }) {
  // Ensure that all fields are initialized to empty strings to avoid uncontrolled input warnings.
  const [data, setData] = useState({
    name: '',
    phone: '',
    email: '',
    address: '',
    state: '',
    pincode: '',
    gst: '',
    city: '',
    panNumber: '',
    doc: '',
    profileImg: ''
  });

  const [upData, setUpData] = useState({});
  const [error, setError] = useState(null);
  const token = useSelector(state => state.login.token);

  useEffect(() => {
    const fetchPartyDetails = async () => {
      try {
        const result = await partyDetail(token, partyId);
       
        if (result?.data) {
          setData(result.data); // Populate form with existing data
        }
      } catch (err) {
        setError("Error fetching party details");
      }
    };

    if (partyId) {
      fetchPartyDetails();
    }
  }, [partyId, token]);

  const handleImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const newProfileImg = e.target.result;
        
        // Update both data and upData
        setData((prev) => ({
          ...prev,
          profileImg: newProfileImg, // Update profile image in the local state for display
        }));
        setUpData((prevUpData) => ({
          ...prevUpData,
          profileImg: newProfileImg, // Add the new profile image to the data to be sent to the API
        }));
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };
  

  const handleChange = (event) => {
    const { name, value } = event.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setUpData((prevUpData) => ({
      ...prevUpData,
      [name]: value,
    }));
  };

  const handleEdit = async () => {
    console.log('Data to be sent:', upData); // Log the upData to ensure profileImg is included
  
    try {
      await editParty(token, upData, partyId);
      updateProfile();
      closeEditModal(); // Close modal after successful edit
    } catch (error) {
      console.error("Error saving party details:", error);
      setError("Error saving party details");
    }
  };
  

  if (!openEditModal) return null;

  return (
    <div className={styles.modalOverlay} onClick={closeEditModal}>
      <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
        <div className={styles.head}>
          <p>Edit Party Details</p>
          <button className={styles.modalClose} onClick={closeEditModal}>X</button>
        </div>
        <div>
          <div className={styles.img}>
            <img src={data.profileImg ? data.profileImg : defaultImg} alt="Party" />
            <input type="file" onChange={handleImageChange} name="profileImg" />
          </div>
          <div className={styles.info}>
            <div className={styles.box}>
              <div className={styles.left}>
                <label>Name</label>
                <input type="text" onChange={handleChange} value={data.name || ''} name="name" />
              </div>
              <div className={styles.right}>
                <label>Mobile No.</label>
                <input type="text" onChange={handleChange} value={data.phone || ''} name="phone" />
              </div>
            </div>
            <div className={styles.box}>
              <div className={styles.left}>
                <label>Email</label>
                <input type="email" onChange={handleChange} value={data.email || ''} name="email" />
              </div>
              <div className={styles.right}>
                <label>Address</label>
                <input type="text" onChange={handleChange} value={data.address || ''} name="address" />
              </div>
            </div>
            <div className={styles.box}>
              <div className={styles.left}>
                <label>GST</label>
                <input type="text" onChange={handleChange} value={data.gst || ''} name="gst" />
              </div>
              <div className={styles.right}>
                <label>PAN No.</label>
                <input type="text" onChange={handleChange} value={data.panNumber || ''} name="panNumber" />
              </div>
            </div>
            <div className={styles.box}>
              <div className={styles.left}>
                <label>State</label>
                <input type="text" onChange={handleChange} value={data.state || ''} name="state" />
              </div>
              <div className={styles.right}>
                <label>City</label>
                <input type="text" onChange={handleChange} value={data.city || ''} name="city" />
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className={styles.saveCancel}>
          <button className={styles.save} onClick={handleEdit}>
            <img src={save} alt="Save" /> Save
          </button>
          <button className={styles.cancel} onClick={closeEditModal}>
            <img src={cancel} alt="Cancel" /> Cancel
          </button>
        </div>
      </div>
    </div>
  );
}

export default EditParty;
