import React, { useState } from 'react';
import styles from './bottomNav.module.css';
import dashboardIcon from '../../Assest/img/dashbordIcon.svg';
import orderIcon from '../../Assest/img/orderIcon.svg';
import partyIcon from '../../Assest/img/partyIcon.svg';
import inventoryIcon from '../../Assest/img/inventoryIcon.svg';
import activeInventoryIcon from '../../Assest/img/activeInventoryIcon.svg';
import activeDashboardIcon from '../../Assest/img/activeDashboardIcon.svg';
import activeOrderIcon from '../../Assest/img/activeOrderIcon.svg';
import activePartyIcon from '../../Assest/img/activePartyIcon.svg';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { sidebarAction } from "../../Reduxstore/sideBarState";
import mediaOrder from '../../Assest/img/mediaorder.svg';
function BottomNav() {

    const activeTab = useSelector((state) => state.sidebar.activeTab);
    const [hovered, setHovered] = useState([false, false, false, false]); 
    const navigate = useNavigate();
    const dispatch = useDispatch();


    const handleActiveTab = (e, tab ,route) => {
        e.preventDefault();
        dispatch(sidebarAction.selectActiveTab(tab));
        navigate(route)
    };

    const handleMouseEnter = (index) => {
        setHovered(hovered.map((_, i) => i === index));
    };

    const handleMouseLeave = () => {
        setHovered([false, false, false, false]); 
    };


  return (
    <div className={styles.container}>
          <div
                    className={`${styles.dashbord}
                        ${(hovered[0] || activeTab === 'dashboard') ? 'bg-[#600AAD] text-white' : 'text-[#600AAD]'}`}
                    onClick={(e) => handleActiveTab(e, 'dashboard','/dashboard')}
                    onMouseEnter={() => handleMouseEnter(0)}
                    onMouseLeave={handleMouseLeave}
                   
                >
                    <img
                        src={(hovered[0] || activeTab === 'dashboard') ? activeDashboardIcon : dashboardIcon}
                  alt="dashboard icon"
                  className="w-5 h-5  ml-2 mx-3"

                    />
                    <Link className={` ${(hovered[0] || activeTab === 'dashboard') ? 'text-white' : 'text-[#600AAD]'}`} to="/dashboard">
                        Home
                    </Link>
            
          </div>
          
          <div
          className={`${styles.dashbord}
                        ${(hovered[1] || activeTab === 'order') ? 'bg-[#600AAD] text-white' : 'text-[#600AAD]'}`}
                    onClick={(e) => handleActiveTab(e, 'order','/order')}
                    onMouseEnter={() => handleMouseEnter(1)}
                    onMouseLeave={handleMouseLeave}
                >
                    <img
                        src={(hovered[1] || activeTab === 'order') ? activeOrderIcon : orderIcon}
                  alt="order icon"
                  className="w-5 h-5  ml-2 mx-3"

                    />
                    <Link className={` ${(hovered[1] || activeTab === 'order') ? 'text-white' : 'text-[#600AAD]'}`} to="/order">
                        Order
                    </Link>
          </div>
          
          <div className={`${styles.dashbord1}`}
                    onClick={(e) => handleActiveTab(e, '/order/selectDate','/order/selectDate')}
                    onMouseEnter={() => handleMouseEnter(2)}
                    onMouseLeave={handleMouseLeave}
                >
                    <img
                        src={mediaOrder}
                  alt="party icon"
                  className={styles.create}
                    />
              <Link className={styles.link} to="/order/selectDate">
                       Create Order
              </Link>
          </div>
          
      <div className={`${styles.dashbord}
                        ${(hovered[2] || activeTab === 'party') ? 'bg-[#600AAD] text-white' : 'text-[#600AAD]'}`}
                    onClick={(e) => handleActiveTab(e, 'party','/party')}
                    onMouseEnter={() => handleMouseEnter(2)}
                    onMouseLeave={handleMouseLeave}
                >
                    <img
                        src={(hovered[2] || activeTab === 'party') ? activePartyIcon : partyIcon}
                        alt="party icon"
                        className="w-5 h-5 ml-3 mx-3 "
                    />
                    <Link className={` ${(hovered[2] || activeTab === 'party') ? 'text-white' : 'text-[#600AAD]'}`} to="/party">
                        Party 
              </Link>
          </div>

      <div className={`${styles.dashbord} 
                        ${(hovered[3] || activeTab === 'inventory') ? 'bg-[#600AAD] text-white' : 'text-[#600AAD]'}`}
                    onClick={(e) => handleActiveTab(e, 'inventory','/category')}
                    onMouseEnter={() => handleMouseEnter(3)}
                    onMouseLeave={handleMouseLeave}
                >
                    <img
                        src={(hovered[3] || activeTab === 'inventory') ? activeInventoryIcon : inventoryIcon}
                        alt="inventory icon"
                        className="w-5 h-5  ml-2 mx-3"
                    />
                    <Link className={` ${(hovered[3] || activeTab === 'inventory') ? 'text-white' : 'text-[#600AAD]'}`} to="/category">
                        Item
                    </Link></div>
    </div>
  )
}

export default BottomNav
