import { baseURL } from '../config'
import axios from 'axios';
import { toast } from "react-toastify";
import { showApiErrorToast } from "./utils";

const fetchAPI = async (url, body) => {
  try {
    const response = await axios.post(`${baseURL}${url}`, body);
    
    // Check if response exists and has data
    if (!response || !response.data) {
      toast.error('No response received from server');
      throw new Error('No response received');
    }

    // If there's an error message in the response, show it and throw
    if (response.data.errorMessage) { 
      toast.error(response.data.errorMessage);
      throw new Error(response.data.errorMessage);
    }

    // If status is not 200 or message is not success, show error
    if (response.status !== 200 || response.data.message !== "success") {
      const errorMsg = response.data.errorMessage || 'Operation failed';
      toast.error(errorMsg);
      throw new Error(errorMsg);
    }

    // If we get here, the request was successful
    return response.data;

  } catch (error) {
    // Handle axios errors
    if (error.response) {
      // Server responded with error status
      const errorMsg = error.response.data.errorMessage || error.response.data.message || 'Server error';
      toast.error(errorMsg);
      throw new Error(errorMsg);
    } else if (error.request) {
      // Request made but no response
      toast.error('No response from server');
      throw new Error('No response from server');
    } else {
      // Other errors
      toast.error(error.message || 'Request failed');
      throw error;
    }
  }
};

export async function login(phone) {
    try {
        const body = {
            phone
        }
        const response = await fetchAPI('auth/login', body)
        return response
    } catch (error) {
        throw error;
    }
}

export async function fetchOtp(otp, phone) {
    try {
        const body = {
           otp,
           phone
        } 
        const response = await fetchAPI('auth/otp', body)
        return response
    } catch (error) {
        throw error;
    }
}

export async function fetchProfile(token) {
    try {
        const response = await axios.get(`${baseURL}vendor/profile`, {
            headers: {
                Authorization: token,
            }
        });

        // Check if response exists and has data
        if (!response || !response.data) {
            toast.error('No response received from server');
            throw new Error('No response received');
        }

        // If there's an error message in the response, show it and throw
        if (response.data.errorMessage) {
            toast.error(response.data.errorMessage);
            throw new Error(response.data.errorMessage);
        }

        // If status is not 200 or message is not success, show error
        if (response.status !== 200 || response.data.message !== "success") {
            const errorMsg = response.data.errorMessage || 'Operation failed';
            toast.error(errorMsg);
            throw new Error(errorMsg);
        }

        return response.data;

    } catch (error) {
        // Handle axios errors
        if (error.response) {
            // Server responded with error status
            const errorMsg = error.response.data.errorMessage || error.response.data.message || 'Server error';
            toast.error(errorMsg);
            throw new Error(errorMsg);
        } else if (error.request) {
            // Request made but no response
            toast.error('No response from server');
            throw new Error('No response from server');
        } else {
            // Other errors
            toast.error(error.message || 'Request failed');
            throw error;
        }
    }
}

function base64ToBlob(base64String, mimeType) {
  const byteCharacters = atob(base64String.split(',')[1]);  // Decode base64 to binary
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);
    const byteNumbers = new Array(slice.length);

    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: mimeType });
}

function base64ToFile(base64, fileName) {
  const arr = base64.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], fileName, { type: mime });
}

export async function editProfile(token, Data) {
  try {
    const formData = new FormData();

    for (const key in Data) {
      if (Data[key] instanceof File) {
        formData.append(key, Data[key], Data[key].name);
      } else if (typeof Data[key] === 'string' && Data[key].startsWith('data:image/')) {
        const file = base64ToFile(Data[key], `${key}.jpg`);
        formData.append(key, file);
      } else {
        formData.append(key, Data[key]);
      }
    }

    const response = await axios.post(`${baseURL}vendor/edit-profile`, formData, {
      headers: {
        Authorization: token,
      },
    });

    // Check if response exists and has data
    if (!response || !response.data) {
      toast.error('No response received from server');
      throw new Error('No response received');
    }

    // If there's an error message in the response, show it and throw
    if (response.data.errorMessage) {
      toast.error(response.data.errorMessage);
      throw new Error(response.data.errorMessage);
    }

    // If status is not 200 or message is not success, show error
    if (response.status !== 200 || response.data.message !== "success") {
      const errorMsg = response.data.errorMessage || 'Operation failed';
      toast.error(errorMsg);
      throw new Error(errorMsg);
    }

    return response.data;

  } catch (error) {
    // Handle axios errors
    if (error.response) {
      // Server responded with error status
      const errorMsg = error.response.data.errorMessage || error.response.data.message || 'Server error';
      toast.error(errorMsg);
      throw new Error(errorMsg);
    } else if (error.request) {
      // Request made but no response
      toast.error('No response from server');
      throw new Error('No response from server');
    } else {
      // Other errors
      toast.error(error.message || 'Request failed');
      throw error;
    }
  }
}

export async function subscriptionDetails(token) {
  try {
    const response = await axios.get(`${baseURL}subscriptions/plans`, {
      headers: {
        Authorization: token,
      }
    });

    // Check if response exists and has data
    if (!response || !response.data) {
      toast.error('No response received from server');
      throw new Error('No response received');
    }

    // If there's an error message in the response, show it and throw
    if (response.data.errorMessage) {
      toast.error(response.data.errorMessage);
      throw new Error(response.data.errorMessage);
    }

    // If status is not 200 or message is not success, show error
    if (response.status !== 200 || response.data.message !== "success") {
      const errorMsg = response.data.errorMessage || 'Operation failed';
      toast.error(errorMsg);
      throw new Error(errorMsg);
    }
    console.log(response)

    return response.data;

  } catch (error) {
    // Handle axios errors
    if (error.response) {
      // Server responded with error status
      const errorMsg = error.response.data.errorMessage || error.response.data.message || 'Server error';
      toast.error(errorMsg);
      throw new Error(errorMsg);
    } else if (error.request) {
      // Request made but no response
      toast.error('No response from server');
      throw new Error('No response from server');
    } else {
      // Other errors
      toast.error(error.message || 'Request failed');
      throw error;
    }
  }
}
export async function subscriptionPlansBuy(token , planId) {

    function removeJWTPrefix(token) {
      return token.replace(/^JWT\s/, '');
  }
  const cleanToken = removeJWTPrefix(token);
  console.log(token , cleanToken);

    window.location.href = `${baseURL}subscriptions/checkout/${planId}?auth=${cleanToken}`;

}
