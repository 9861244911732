import React  from 'react';
import Layout from '../Layout/Layout';
import Home from '../../Home/Home';

function HomePageFlow() {


  return (
    <Layout>
<Home/>
      </Layout>
  )
}

export default HomePageFlow