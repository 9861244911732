// columns.js
import deleteInventory from "../../Assest/img/deleteInventory.svg";

export const Column = (handleDeleteInventory)=> [
  {
    Header: 'Item Name',
    accessor: 'name', // Accessor is the "key" in your data
  },
  {
    Header: 'Quantity',
    accessor: 'quantity',
  },
   
  {
    Header: 'Rate',
    accessor: 'price',
  },
  {
    Header: 'action',
    accessor: 'Details',
    Cell: ({ row }) => ( 
      <div style={{gap:'10px',display: 'flex'}}>
        {/* <button>
        Edit
        </button> */}
        <button onClick={() => handleDeleteInventory(row)}>
          
        <img src={deleteInventory}></img>
            </button>
            </div>
    )
}

    
  ];
