import React, { useEffect, useState } from "react";
import styles from "./order.module.css";
import addParty from "../../Assest/img/addParty.svg";
import defaultImg from "../../Assest/img/defaultImg.svg";
import { useNavigate } from "react-router";
import { orderList } from "../../api/order";
import { useSelector } from "react-redux";
import debounce from "lodash.debounce";

function Order() {
  const navigate = useNavigate();
  const token = useSelector((state) => state.login.token);

  const [order, setOrder] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState("");

  const fetchOrderList = async (search = "", timeFilter = "") => {
    try {
      const response = await orderList(token, search, timeFilter);
      if (response.message === "success") {
        setOrder(response.data);
        console.log(response.data);
      }
    } catch (error) {
      console.error("Failed to fetch order list:", error);
    }
  };

  useEffect(() => {
    fetchOrderList(searchTerm, filter);
  }, [token, searchTerm, filter]);

  const debouncedFetchOrderList = debounce((search) => {
    fetchOrderList(search, filter);
  }, 300);

  const handleOpenOrderDetails = (id) => {
    console.log(id);
    navigate("/order/orderDetail", { state: { orderId: id } });
  };

  const handleSearchInputChange = (e) => {
    setSearchTerm(e.target.value);
    debouncedFetchOrderList(e.target.value);
  };

  const handleFilterChange = (filterValue) => {
    setFilter(filterValue);
  };

  const navigateToCreateOrder = () => {
    navigate("/order/selectDate");
  };

  return (
    <div className={styles.container}>
      <div className={styles.orderTopTab}>
        <div className={styles.tab1}>
          <form
            className={`d-flex ${styles.search}`}
            onSubmit={(e) => e.preventDefault()}
          >
            <input
              className={`form-control text-custom me-2 w-30 ${styles.textCustom}`}
              type="search"
              placeholder="Search"
              aria-label="Search"
              onChange={handleSearchInputChange}
              value={searchTerm}
            />
            {/* <button className={`btn ${styles.btnCustom}`} type="submit">
              Search
            </button> */}
          </form>

          <button className={styles.addOrder} onClick={navigateToCreateOrder}>
            <img src={addParty} alt="Add Party" />
            Create Order
          </button>
        </div>
        <div className={styles.tab2}>
          <button
            className={`${styles.btnCustoms} ${
              filter === "" ? styles.active : ""
            }`}
            onClick={() => handleFilterChange("")}
          >
            All
          </button>
          <button
            className={`${styles.btnCustoms} ${
              filter === "before" ? styles.active : ""
            }`}
            onClick={() => handleFilterChange("before")}
          >
            Before
          </button>
          <button
            className={`${styles.btnCustoms} ${
              filter === "upcoming" ? styles.active : ""
            }`}
            onClick={() => handleFilterChange("upcoming")}
          >
            coming
          </button>
          <button
            className={`${styles.btnCustoms} ${
              filter === "ongoing" ? styles.active : ""
            }`}
            onClick={() => handleFilterChange("ongoing")}
          >
            Running
          </button>
          <button
            className={`${styles.btnCustoms} ${
              filter === "cancelled" ? styles.active : ""
            }`}
            onClick={() => handleFilterChange("cancelled")}
          >
            Cancel
          </button>
        </div>
      </div>
      <div className={styles.partyCardContainer}>
        {order.map((e, index) => (
          <div
            key={index}
            className={`card-body ${styles.box}`}
            onClick={() => handleOpenOrderDetails(e.orderId)}
          >
            <div className={styles.data}>
              <p className={styles.name}>{e.partyName}</p>
              <p className={styles.email}>{e.partyEmail}</p>
              <p className={styles.date}>{e.orderDate}</p>
              <p className={styles.value}>Order Value: {e.orderValue}/-</p>
            </div>
            <div className={styles.infoDiv}>
              <img
                src={e.userProfileImage ? e.userProfileImage : defaultImg}
                className={styles.cardImg}
                alt="User Profile"
              />
              <div
                className={`${styles.btnType} ${
                  e.orderStatus === "pending"
                    ? styles.gray
                    : e.orderStatus === "completed"
                    ? styles.green
                    : e.orderStatus === "cancelled"
                    ? styles.red
                    : e.orderStatus === "dispatched"
                    ? styles.purple
                    : ""
                }`}
              >
                {e.orderStatus}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Order;
