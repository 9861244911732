import React  from 'react';
import Layout from '../Layout/Layout';
import CreateOrder from '../../orders/CreateOrder';

function createOrderFlow() {


  return (
    <Layout>
    <CreateOrder />
  </Layout>
  )
}

export default createOrderFlow