import React  from 'react';
import Layout from '../Layout/Layout';
import EditInventory from '../../inventory/EditInventory';

function EditInventoryFlow() {


  return (
    <Layout>
    <EditInventory />
  </Layout>
  )
}

export default EditInventoryFlow
