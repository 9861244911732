import React, { useState, useEffect } from "react";
import loginScreen from "../../Assest/img/loginScreen.png";
import { useNavigate } from "react-router";
import { useRef } from "react";
import "./login.css";
import loginSideScreen from "../../Assest/img/loginSideScreen.svg";
import logo from '../../Assest/img/logo.svg';
import welcome from '../../Assest/img/welcome.svg' 
import { login } from '../../api/login';
import { toast } from "react-toastify";
    
function Login({mobileNumberUpdate}) {
  const [mobileNumber, setMobileNumber] = useState("");
  const [isButtonDisabled, setButtonDisabled] = useState(true);

  const inputRef = useRef(null);

  useEffect(() => {
    // Auto-focus the input field when the component mounts
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const fetchLogin = async ()=> {
      
          await login(mobileNumber) 
          mobileNumberUpdate(mobileNumber)
          toast.success('otp send successfully')
    }

  const handleMobileNumber = (event) => {
    const value = event.target.value;
    if (/^\d{0,10}$/.test(value)) {
      setMobileNumber(value);
        setButtonDisabled(value.length !== 10); // Disable button if input is empty 
      }
    event.target.focus();
  };



  return (
    <div className="login-container d-flex justify-content-center align-items-center">
      <div className="login-left-screen">
        <img src={loginSideScreen} />
      </div>
      <div className="login-right-screen">
        <div className="login-form-container">
          <div className="login-head">
            <h4>Log in / Sign up</h4>
                  </div>
                  <div className="login-web-img">
                      <img  className='logo' src={logo} />
                      <img  className='welcome' src={welcome}/>
                  </div>

          <div className="login-img">
            <img src={loginScreen} />
            <p>india's # 1 Booking app for Renters</p>
          </div>
          <div className="login-mobile">
            <label>Enter your mobile number</label>
            <input
              type="tel"
              id="mobile-number"
              name="mobile-number"
              placeholder="Enter here"
              value={mobileNumber}
              onChange={handleMobileNumber}
              ref={inputRef}
              required
            />
          </div>

          <div className=" btn-otp">
            <button
              className="btn"
              disabled={isButtonDisabled}
              onClick={fetchLogin}
            >
              {" "}
              Send OTP
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
