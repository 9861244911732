import { baseURL } from "../config";
import axios from "axios";
import { toast } from "react-toastify";
  
export async function allParty(token,search='') {
    try {
       

   const response = await axios.get(`${baseURL}parties/list-all?draw=100&page=0&searchKey=${search}`,{
    headers: {
      Authorization: token,
    } 
   })
        if (response && response.status === 200 && response.data.message === 'success') {
            return response.data;
        }else {
            const errorMessage = response.data.errorMessage || "Failed to fetch parties";
            toast.error(errorMessage);
            throw new Error(errorMessage);
         
          }
    } catch (error) {
        
      console.error("Server responded with an error:", error);
      let errorMessage = "Failed to fetch parties";
      
      if (error.response) {
        errorMessage = error.response.data.message || 
                      error.response.data.errorMessage || 
                      "Error fetching party list";
                      
        if (error.response.status === 401) {
          errorMessage = "Session expired. Please login again.";
        }
      } else if (error.request) {
        errorMessage = "Network error. Please check your connection.";
      }

      toast.error(errorMessage);
      throw error;
   
    throw error;
    }
}

export async function partyDetail(token , partyId) {
  try {
     

 const response = await axios.get(`${baseURL}parties/details/${partyId}`,{
  headers: {
    Authorization: token,
  } 
 })
      if (response && response.status === 200 && response.data.message === 'success') {
          return response.data;
      }else {
          const errorMessage = response.data.errorMessage || "Failed to fetch party details";
          toast.error(errorMessage);
          throw new Error(errorMessage);
       
        }
  } catch (error) {
      
    console.error("Server responded with an error:", error);
    let errorMessage = "Failed to fetch party details";
    
    if (error.response) {
      errorMessage = error.response.data.message || 
                    error.response.data.errorMessage || 
                    "Error fetching party details";
                      
      if (error.response.status === 404) {
        errorMessage = "Party not found";
      } else if (error.response.status === 401) {
        errorMessage = "Session expired. Please login again.";
      }
    } else if (error.request) {
      errorMessage = "Network error. Please check your connection.";
    }

    toast.error(errorMessage);
    throw error;
 
  throw error;
  }
}



function base64ToBlob(base64String, mimeType) {
  const byteCharacters = atob(base64String.split(',')[1]);  // Decode base64 to binary
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);
    const byteNumbers = new Array(slice.length);

    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: mimeType });
}


export async function addParty(token ,Data) {
  try {
      const formData = new FormData();
      for (const key in Data) {
          if (Data[key] instanceof File) {
              formData.append(key, Data[key], Data[key].name);
          } else {
              formData.append(key, Data[key]);
          }
      }

      formData.forEach((value, key) => {
          console.log(`FormData Key: ${key}, Value: ${value}`);
      });


      const response = await axios.post(`${baseURL}parties/add-party`,formData,{
      headers: {
        Authorization: token,
      }
    })
    
    if (response.status === 200 && response.data.message === "success") {
      return response.data;
    } else {
      console.error("Unexpected response:", response);
      toast.error(`Unexpected response: ${response.data.errorMessage}`);
    }
  } catch (error) {
    console.error("Error:", error.response);
    toast.error(`Error: ${error.response?.data?.message || error.response?.statusText}`);
    throw error;
  }
}





export async function editParty(token, vendorData, partyId) {
  try {
    const formData = new FormData();

    // Convert base64 string to Blob (only for the image)
    if (vendorData.profileImg && vendorData.profileImg.startsWith('data:')) {
      const mimeType = vendorData.profileImg.match(/data:(.*);base64/)[1];  // Extract the MIME type
      const imageBlob = base64ToBlob(vendorData.profileImg, mimeType);

      // Append image as Blob to FormData
      formData.append('profileImg', imageBlob, 'profile.png');
    }

    // Append other form data
    for (const key in vendorData) {
      if (key !== 'profileImg') {
        formData.append(key, vendorData[key]);
      }
    }

    // Make the request with axios
    const response = await axios.post(
      `${baseURL}parties/edit-party/${partyId}`,
      formData,
      {
        headers: {
          Authorization: token,
          // Let axios set the correct Content-Type for multipart
        },
      }
    );

    if (response.status === 200 && response.data.message === "success") {
      return response.data;
    } else {
      console.error("Unexpected response:", response);
      toast.error(`Unexpected response: ${response.data.errorMessage}`);
    }
  } catch (error) {
    console.error("Error:", error.response);
    toast.error(`Error: ${error.response?.data?.message || error.response?.statusText}`);
    throw error;
  }
}
