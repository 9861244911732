import { baseURL } from '../config';
import axios from 'axios';
import { toast } from 'react-toastify';

export async function getCategoryList(token) {
    try {
       

   const response = await axios.get(`${baseURL}inventories/categories`,{
    headers: {
      Authorization: token,
    } 
   })
        if (response && response.status === 200 && response.data.message === 'success') {
            return response.data;
        }else {
            toast.error(`Unexpected response: ${response.data.errorMessage}`);
            console.error("Unexpected response:", response ,response.data.errorMessage);
         
          }
    } catch (error) {
        
      console.error("Server responded with an error:", error);
      toast.error(`Error: ${error || error}`);
   
    throw error;
    }
}

export async function inventoryList(token,categoryId,search='') {
  try {
     

 const response = await axios.get(`${baseURL}inventories/list-inventory?draw=20&page=0&categoryId=${categoryId}&search=${search}`,{
  headers: {
    Authorization: token,
  } 
 })
      if (response && response.status === 200 && response.data.message === 'success') {
          return response.data;
      }else {
          toast.error(`Unexpected response: ${response.data.errorMessage}`);
          console.error("Unexpected response:", response ,response.data.errorMessage);
       
        }
  } catch (error) {
      
    console.error("Server responded with an error:", error);
    toast.error(`Error: ${error || error}`);
 
  throw error;
  }
}

export async function inventoryDetail(token,inventoryId) {
  try {
     
console.log(inventoryId )
 const response = await axios.get(`${baseURL}inventories/details/${inventoryId}`,{
  headers: {
    Authorization: token,
  } 
 })
      if (response && response.status === 200 && response.data.message === 'success') {
          return response.data;
      }else {
          toast.error(`Unexpected response: ${response.data.errorMessage}`);
          console.error("Unexpected response:", response ,response.data.errorMessage);
       
        }
  } catch (error) {
      
    console.error("Server responded with an error:", error);
    toast.error(`Error: ${error || error}`);
 
  throw error;
  }
}

export async function addInventory(token ,Data) {
  try {
      const formData = new FormData();
      for (const key in Data) {
          if (Data[key] instanceof File) {
              formData.append(key, Data[key], Data[key].name);
          } else {
              formData.append(key, Data[key]);
          }
      }

      formData.forEach((value, key) => {
          console.log(`FormData Key: ${key}, Value: ${value}`);
      });


      const response = await axios.post(`${baseURL}inventories/add-inventory`,formData,{
      headers: {
        Authorization: token,
      }
    })
    
    if (
      response &&
      (response.status === 200) & (response.data.message === "success")
    ) {
      return response.data;
    }else {
      toast.error(`Unexpected response: ${response.data.errorMessage}`);
      console.error("Unexpected response:", response ,response.data.errorMessage);
   
    }
  } catch (error) {
    console.error("Server responded with an error:", error.response);
    toast.error(`Error: ${error.response.data.message || error.response.statusText}`);
 
  throw error;
  }
}

export async function editInventory(token ,Data,inventoryId) {
  try {
      const formData = new FormData();
      for (const key in Data) {
          if (Data[key] instanceof File) {
              formData.append(key, Data[key], Data[key].name);
          } else {
              formData.append(key, Data[key]);
          }
      }

      formData.forEach((value, key) => {
          console.log(`FormData Key: ${key}, Value: ${value}`);
      });


      const response = await axios.post(`${baseURL}inventories/edit-inventory/${inventoryId}`,formData,{
      headers: {
        Authorization: token,
      }
    })
    
    if (
      response &&
      (response.status === 200) & (response.data.message === "success")
    ) {
      return response.data;
    }else {
      toast.error(`Unexpected response: ${response.data.errorMessage}`);
      console.error("Unexpected response:", response ,response.data.errorMessage);
   
    }
  } catch (error) {
    console.error("Server responded with an error:", error.response);
    toast.error(`Error: ${error.response.data.message || error.response.statusText}`);
 
  throw error;
  }
}

export async function godownList(token) {
  try {
     
 const response = await axios.get(`${baseURL}inventories/godown`,{
  headers: {
    Authorization: token,
  } 
 })
      if (response && response.status === 200 && response.data.message === 'success') {
          return response.data;
      }else {
          toast.error(`Unexpected response: ${response.data.errorMessage}`);
          console.error("Unexpected response:", response ,response.data.errorMessage);
       
        }
  } catch (error) {
      
    console.error("Server responded with an error:", error);
    toast.error(`Error: ${error || error}`);
 
  throw error;
  }
}


export async function addGodown(token,name,address) {
  try {
    

    const response = await axios.post(`${baseURL}inventories/add-godown`, {
      name,address
    },{
      headers: {
        Authorization: token,
      }
    })
    
    if (
      response &&
      (response.status === 200) & (response.data.message === "success")
    ) {
      return response.data;
    }else {
      toast.error(`Unexpected response: ${response.data.errorMessage}`);
      console.error("Unexpected response:", response ,response.data.errorMessage);
   
    }
  } catch (error) {
    console.error("Server responded with an error:", error.response);
    toast.error(`Error: ${error.response.data.message || error.response.statusText}`);
 
  throw error;
  }
}

export async function addCategory(token,name) {
  try {
    

    const response = await axios.post(`${baseURL}inventories/add-category`, {
      name
    },{
      headers: {
        Authorization: token,
      }
    })
    
    if (
      response &&
      (response.status === 200) & (response.data.message === "success")
    ) {
      return response.data;
    }else {
      toast.error(`Unexpected response: ${response.data.errorMessage}`);
      console.error("Unexpected response:", response ,response.data.errorMessage);
   
    }
  } catch (error) {
    console.error("Server responded with an error:", error.response);
    toast.error(`Error: ${error.response.data.message || error.response.statusText}`);
 
  throw error;
  }
}