import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { handleGetOpenTenders, handleSubmitBid, handleGetBidsForTender } from '../../../api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Tenderdetails.css';
import Profile from './img/Rectangle.png';
import Layout from '../../../../Components/pages/Layout/Layout';
// import Spinner from '../spinner/Spinner';

const TenderDetails = ({ userRole }) => {
  const { id } = useParams();
  const [activeTab, setActiveTab] = useState('details');
  const [tender, setTender] = useState(null);
  const [bids, setBids] = useState([]);
  const [bidAmount, setBidAmount] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [submitting, setSubmitting] = useState(false); // New state for bid submission

  useEffect(() => {
    const fetchTenderDetails = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await handleGetOpenTenders();
        if (response.success) {
          const selectedTender = response.data.find((tender) => tender._id === id);
          if (selectedTender) {
            setTender(selectedTender);
          } else {
            setError('Tender not found.');
          }
        } else {
          setError('Failed to load tenders.');
        }
      } catch (err) {
        setError('An error occurred while fetching tenders.');
        console.error('Error fetching tenders:', err);
      } finally {
        setLoading(false);
      }
    };

    const fetchBidsForTender = async () => {
      try {
        const response = await handleGetBidsForTender(id);
        if (response.success) {
          const sortedBids = Array.isArray(response.data.data)
            ? response.data.data.sort((a, b) => a.price - b.price)
            : [];
          setBids(sortedBids);
        } else {
          toast.error(response.msg || 'Failed to load bids.');
        }
      } catch (err) {
        if (err.response && err.response.data && err.response.data.msg) {
          toast.error(err.response.data.msg);
        } else {
          toast.error('An error occurred while fetching bids.');
        }
        console.error('Error fetching bids:', err);
      }
    };

    fetchTenderDetails();
    fetchBidsForTender();
  }, [id]);

  // Handle bid submission
  const handleBidSubmit = async () => {
    if (!bidAmount || isNaN(bidAmount) || bidAmount <= 0) {
      toast.error('Please enter a valid bid amount.');
      return;
    }

    setSubmitting(true); // Set submitting to true before making the API call

    try {
      const response = await handleSubmitBid(id, { price: bidAmount });
      if (response.success) {
        const newBid = response.data.data;
        setBids((prevBids) => {
          const updatedBids = [...prevBids, newBid];
          return updatedBids.sort((a, b) => a.price - b.price);
        });
        setBidAmount('');
        toast.success('Bid submitted successfully!');
      } else {
        toast.error(response.msg || 'Failed to submit bid.');
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.msg) {
        toast.error(err.response.data.msg);
      } else {
        toast.error('An error occurred while submitting the bid.');
      }
      console.error('Error submitting bid:', err);
    } finally {
      setSubmitting(false); // Set submitting to false after the API call
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    });
  };

  // if (loading) return <p><Spinner></Spinner></p>;
  if (error) return <p className="error-message">{error}</p>;

  return (
    <Layout>

    <div className="bid-container">
      {/* Tender Details Section */}
      {tender && (
        <div className="bid-details-section">
          <h2>{tender.eventName}</h2>
          <div className="bid-detail-item">
            <strong>Service Required:</strong> <span>{tender.servicesRequired}</span>
          </div>
          <div className="bid-detail-item">
            <strong>City:</strong> <span>{tender.location}</span>
          </div>
          <div className="bid-detail-item">
            <strong>Budget:</strong> <span>{tender.budget}</span>
          </div>
          <div className="bid-detail-item">
            <strong>Status:</strong> <span>{tender.status}</span>
          </div>
          <div className="bid-detail-item">
            <strong>Expire Date:</strong> <span>{formatDate(tender.expirationDate)}</span>
          </div>
          <div className="bid-detail-item">
            <strong>Start Date:</strong> <span>{formatDate(tender.startDate)}</span>
          </div>
          <div className="bid-detail-item">
            <strong>End Date:</strong> <span>{formatDate(tender.endDate)}</span>
          </div>
          <div className="bid-description">
            <strong>Description:</strong> <p>{tender.description}</p>
          </div>
        </div>
      )}

      {/* Bidding Section */}
      <div className={`bid-bidding-section ${activeTab === 'bid' ? 'active-section' : ''}`}>
        <h3>Bids</h3>
        {bids && bids.length > 0 ? (
          bids.map((bid) => (
            <div key={bid._id} className="bid-item">
            
              
                                  <img src={Profile} alt="User Avatar" />
                  <span className="bid-name">{bid.vendor.name}</span>
                
              <span className="bid-amount">
                ₹{bid.price} - Applied on <span className="bid-date">{formatDate(bid.AppliedDate)}</span>
              </span>
              <div className="bid-details"></div>
            </div>
          ))
        ) : (
          <p>No bids have been submitted yet.</p>
        )}

        {/* Bid Submission Input */}
        <div className="bid-input-section">
          <input
            type="text"
            placeholder="Enter Bid Amount"
            value={bidAmount}
            onChange={(e) => setBidAmount(e.target.value)}
            disabled={submitting} // Disable input when submitting
          />
          <button onClick={handleBidSubmit} disabled={submitting}> {/* Disable button when submitting */}
            {submitting ? 'Submitting...' : 'Bid'} {/* Show 'Submitting...' when submitting */}
          </button>
        </div>
      </div>

      {/* ToastContainer for toast notifications */}
      <ToastContainer />
    </div>
    </Layout>
  );
};

export default TenderDetails;
