import React  from 'react';
import Layout from '../Layout/Layout';
import EditCreateOrder from '../../orders/EditCreateOrder';

function EditCreateOrderFlow() {


  return (
    <Layout>
    <EditCreateOrder />
  </Layout>
  )
}

export default EditCreateOrderFlow