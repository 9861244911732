import React, {useState ,useEffect,useMemo} from "react";
import save from "../../Assest/img/save.svg";
import cancel from "../../Assest/img/cancel.svg";
import "./editInventory.css";
import { useNavigate, useLocation } from "react-router";
import { editInventory, inventoryDetail } from "../../api/inventoryAPI";
import { useSelector } from "react-redux";
import { getCategoryList } from "../../api/inventoryAPI";
import { Column} from "./godownColumn";
import { useTable, usePagination } from "react-table";
import EditGodownModal from './EditGodownModal';
import AddCategoryModal from "./AddCategoryModal";
import AddGodownModal from "./AddGodownModal";

function EditInventory() {
  const navigate = useNavigate();
  const location = useLocation();
  const { inventoryId } = location.state || {};

  const [data, setData] = useState({});
  const [upData, setUpData] = useState({});
  const [error, setError] = useState(null);
  const token = useSelector((state) => state.login.token);
  const [category, setCategory] = useState([]);
  const [isGodownModalOpen, setIsGodownModalOpen] = useState(false);
  const [godown, setGodown] = useState([]);
  const [openAddCategoryModal, setOpenAddCategoryModal] = useState(false);
  const [previousGodownData, SetPreviousGodownData] = useState({})
  const [openAddGodownModal, setOpenAddGodownModal] = useState(false)
  
  useEffect(() => {
    const fetchInventoryDetails = async () => {
      try {
        const result = await inventoryDetail(token, inventoryId);
        const categories = await getCategoryList(token);
        setCategory(categories.data);
        setData(result.data);
        setGodown(result.data.godownAddition.map(e => ({ ...e, quantity: e.totalQuantity, godownId: e.godownId })))
      } catch (err) {
        setError("Error fetching inventory details");
        setCategory([]); // Fallback to empty array in case of an error
      }
    };
    
    if (inventoryId) {
      fetchInventoryDetails();
    }
  }, [inventoryId, token]);
  console.log(godown)
  
  // Function to open modal
  const openEditGodownModal = (row) => {
    setIsGodownModalOpen(true);
    SetPreviousGodownData(row.original)
  };

  // Function to close modal
  const closeGodownModal = () => {
    setIsGodownModalOpen(false);
  };
  
  const memoizedColumns = useMemo(() => Column(openEditGodownModal), []);
  
  // const memoizedData = useMemo(() => go, [data]);
  const memoizedData = useMemo(() => godown, [godown]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
   
  } = useTable(
    {
      columns: memoizedColumns,
      data: memoizedData,
      initialState: { pageIndex: 0, pageSize: 9 },
    },
    usePagination
  );
  console.log(memoizedData)

  const handleImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      setData((prevData) => ({
        ...prevData,
        img: file, // Store the image as a File object
      }));

      setUpData((prevData) => ({
        ...prevData,
        img: file, // Add the image file to the update data
      }));
    }
  };

  // Handle input changes for other fields
  const handleChange = (event) => {
    const { name, value } = event.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    setUpData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleOpenAddCategoryModal = () => {
    setOpenAddCategoryModal(true)
  }
  const handleCloseAddCategoryModal = () => {
    setOpenAddCategoryModal(false)
  }

  const handleOpenAddGodownModal = () => {
    setOpenAddGodownModal(true)
  }
  const handleCloseAddGodownModal = () => {
    setOpenAddGodownModal(false)
  }

  // Save the updated inventory details
  const handleSave = async () => {
    try {
      await editInventory(token, upData, inventoryId);
      navigate(-1);
    } catch (error) {
      setError("Error saving inventory");
    }
  };


  const saveGodownModalData = (godownData) => {
    console.log(godownData);
  
    setGodown((prevGodown) => {
      // Create a copy of the previous godown array and update the relevant item
      const updatedGodownData = prevGodown.map((g) =>
        g.godownId === previousGodownData.godownId
          ? { ...godownData, quantity: godownData.totalQuantity, godownId: godownData.godownId }
          : g
      );
  
      // Update `data` and `upData` here using the updated state
      setData((prevData) => ({ ...prevData, godownAddition: updatedGodownData }));
      setUpData((prevData) => ({ ...prevData, godownAddition: JSON.stringify(updatedGodownData) }));
  
      return updatedGodownData; // Update the `godown` state with the new array
    });
  };  
  
  console.log(data)

   
  

  const back = () => {
    navigate(-1);
  };
  console.log(data)

  // Fallback to display a default image if data.img is not a File object
  const displayImage = () => {
    if (data.img instanceof File) {
      return URL.createObjectURL(data.img);
    } else if (data.img) {
      return data.img; 
    } else {
      return ""; 
    }
  };

  if (error) return <p>{error}</p>;

  return (
    <div className="inventoryDetail-container">
      <div className="main-edit">
        <div className="left-edit">
          <img src={displayImage()} alt="Inventory" />
          <input type="file" onChange={handleImageChange} />
        </div>
        <div className="right-edit">
          <div className="card card-edit">
            <div className="head-edit">
              <p>Edit Item</p>
              <div className="save-cancel">
                <button className="edit" onClick={handleSave}>
                  <img src={save} alt="Save" /> Save
                </button>
                <button className="delete" onClick={back}>
                  <img src={cancel} alt="Cancel" /> Cancel
                </button>
              </div>
            </div>
            <div className="info-edit">
             
              <div className="item">
                <div>
                  <label>Item Number</label>
                  <input
                    name="itemNumber"
                    value={data.uniqueId || ""}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <label>Item Name</label>
                  <input
                    type="text"
                    name="name"
                    value={data.name || ""}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="avail-unit">
                <div>
                  <label>Available</label>
                  <input
                    type="number"
                    name="availableQuantity"
                    value={data.availableQuantity || ""}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <label>Unit</label>
                  <input
                    type="text"
                    name="totalQuantity"
                    value={data.totalQuantity || ""}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="edit-price">
                <div>
                  <label>Purchase</label>
                  <input
                    type="number"
                    name="purchasedPrice"
                    value={data.purchasedPrice || ""}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <label>Rent Price</label>
                  <input
                    type="number"
                    name="rentPrice"
                    value={data.dayRent || ""}
                    onChange={handleChange}
                  />
                </div>
                
              </div>
                <div className="form-group">
                  <label>Category:</label>
                  <select
                    name="categoryId"
                    value={data.categoryId}
                    onChange={handleChange}
                    required
                  >
                    <option value="" disabled>
                      Select Category
                    </option>
                    {category.map((category) => (
                      <option key={category.categoryId} value={category.categoryId}>
                        {category.name}
                      </option>
                    ))}
                  </select>
                <button className='addCat'onClick={handleOpenAddCategoryModal}>Add New Category</button>
              
                <div className="description">
                <label>Description</label>
                <textarea
                  maxLength={30}
                  name="description"
                  value={data.description || ""}
                  onChange={handleChange}
                />
              </div>
                  </div>
                <div className="edit-godown">
                <div className='table'>
          <table {...getTableProps()} className="table " id="customers">
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()} key={column.id}>
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.length > 0 ? (
                page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()} key={row.id}>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()} key={cell.column.id}>
                            {cell.render("Cell")}
                           
                          </td>
                        );
                      })}
                    </tr>
                  );
                })
              ) : (
                <tr>
                            <td colSpan={memoizedColumns.length}>No Data Available</td>
                            
                </tr>
              )}
            </tbody>
          </table>
        </div>
                </div>
                <div className='add'>
              <button className='addGodown' onClick={handleOpenAddGodownModal}> Add New Godown</button>
              </div>
                </div>
             
          </div>
          <AddGodownModal openModal={openAddGodownModal} closeModal={handleCloseAddGodownModal} />
              <EditGodownModal openModal={isGodownModalOpen} closeModal={closeGodownModal} onSave={saveGodownModalData} previousGodownData={previousGodownData}  />
          <AddCategoryModal openModal={openAddCategoryModal} closeModal={handleCloseAddCategoryModal}/>
          </div>
      </div>
    </div>
  );
}

export default EditInventory;                                                                                           