export const Column = (openEditGodownModal) => [
    {
      Header: 'Godown Name',
      accessor: 'godownName',
    },
    {
      Header: 'Total Quantity',
      accessor: 'totalQuantity',
    },
    {
      Header: 'Actions',
      accessor: 'actions',
      Cell: ({row}) => (
        <div>
          <button className="btn" onClick={()=>openEditGodownModal(row)}>
            Edit
          </button>
        </div>
      ),
    },
  ];
  
  // data.js
  export const go = [
    {
      godown: 'Godown 1',
      quantity: 10,
      availableQuantity: 8, // Added available quantity
    },
    {
      godown: 'Godown 2',
      quantity: 5,
      availableQuantity: 3, // Added available quantity
    },
  ];
  