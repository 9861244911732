import React from 'react'
import styles from './home.module.css';
import homeGraph from '../../Assest/img/homeGraph.svg';
import homeUp from '../../Assest/img/homeUp.svg';
import homeRun from '../../Assest/img/homeRun.svg';
import homeWeb from '../../Assest/img/homeWeb.svg';
import homeEcom from '../../Assest/img/homeEcom.svg';
import workReturn from '../../Assest/img/workReturn.svg'
import workDelivery from '../../Assest/img/workDelivery.svg'
import { Link } from 'react-router-dom'
import rajadv from '../../Assest/img/raj adv.svg'

function Home() {
  return (
     <div className={styles.container}>

      <div className={styles.cardContainer}>
        <div className={styles.home}>

          <div className={styles.box1}>
            <div className={styles.up}>
              <img src={homeUp}></img>
              <img src={homeGraph}></img>
            </div>
            <div className={styles.down}>
              <p>10</p>
              <p>Upcoming</p>
            </div>
          </div>

          <div className={styles.box2}>
            <div className={styles.up}>
              <img src={homeRun}></img>
              <img src={homeGraph}></img>
            </div>
            <div className={styles.down}>
              <p>10</p>
              <p>Running</p>
            </div>
          </div>

          <div className={styles.box3}>
            <div className={styles.up}>
              <img src={homeWeb}></img>
              <img src={homeGraph}></img>
            </div>
            <div className={styles.down}>
              {/* <p>10</p> */}
             <Link to={'/Alltenders'}><button className={styles.seeTenderButtons}> See Tenders</button></Link> 
            </div>
          </div>

          <div className={styles.box4}>
            <div className={styles.up}>
              <img src={homeEcom}></img>
              <img src={homeGraph}></img>
            </div>
            <div className={styles.down}>
              <p>10</p>
              <p>E-com</p>
            </div>
          </div>
        </div>
        
      <div className={styles.work}>
        <img className={styles.add} src={rajadv}/>
          {/* <p className={styles.text}>Work to do</p> */}
          {/* <div className={styles.workBox}> 
            
        <div className={styles.box5}>
<div className={styles.img}><img src={workReturn}></img></div>
          <div>
          <p>10</p>
          <p>Orders Return Due</p>
          </div>
          </div>
          
          <div className={styles.box6}>
          <div className={styles.img}><img src={workDelivery}></img></div>
          <div>
          <p>10</p>
          <p>Delivery Due</p>
          </div>
          </div>
          </div> */}
          </div>

        </div>
          </div>
  )
}

export default Home


